import { Box } from '@chakra-ui/react'
import { FileCard } from 'components/RFQ/FileCard'
import React from 'react'

const Documentation = ({ documentAttachments,documentDescription,...props}) => {

  return (
    <>
     <Box className='min-h-40 w-full border rounded-md shadow-lg grid p-10 mb-8 ' {...props}>
     <h1 className='text-xl font-bold'>Documentation Description</h1>
      <p className='mt-3 [&>ol]:!list-decimal [&>ul]:!list-disc [&>ol]:ml-5 [&>ul]:ml-5'
         dangerouslySetInnerHTML={{__html:documentDescription}}/>
     </Box>
      <Box className='min-h-80 w-full border rounded-md shadow-xl grid p-10 ' {...props}>
        <div>
          {documentAttachments?documentAttachments.map((category, index) => {
            return(
              <div key={index}>
                <h1 className='text-xl font-bold'>{category.category}</h1>
                <div>
                  {category?.documents.map((doc, index) => 
                    <FileCard key={index} file={doc} isPreview={true}/>
                  )}
                </div>
              </div>
          )
        }):[]}
          </div>
      </Box>
    </>
  )
}

export default Documentation

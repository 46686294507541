import React from 'react';
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
} from "@chakra-ui/react";
import { CloseButton } from 'components/RFQ/CloseButton';
import { TabHeader } from 'components/TabHeader';
import { PreviewRFQButtonTab } from 'components/Pages/CreateRFQ/PreviewRFQButtonTab';
import * as constant from '../../../../../utils/constants';
import { Questionnaire } from './Questionnaire';
import { Form, Formik, useFormikContext } from 'formik';
import { TenderCost } from './TenderCost';
import { Documentation } from './Documentation';
import { useSelector } from 'react-redux';
import moment from 'moment';


const PreviewRFQ = ({ isOpen, rfqData, onClose,unitMeasures,documentCategories, ...props }) => {
    const {values:formValues} = useFormikContext();
    const companySize = useSelector((state) => state?.search?.activeCompany?.size_name || '-') ;

    const [currentTab, setCurrentTab] = React.useState(constant.PROJECT_DETAILS);

    const initialDataQuote = {quote:formValues?.quote} || {quote:[]};
    
    const documentAttachments = formValues.documents.filter((doc)=> !doc?.isRemove).reduce((final,doc) => {
        const category = documentCategories.find((docs) => docs.value === doc.category_id) 
        const haveIndex = final.findIndex((docs) => docs.category === category.label)
        if(haveIndex >= 0){
            const docObj = doc.document instanceof File ? doc.document : doc
            final[haveIndex].documents = [...final[haveIndex].documents,docObj]
            return final
        }else{
            const docObj = doc.document instanceof File ? doc.document : doc
            final.push({
                category:category.label,
                documents:[docObj]
            })
            return final
        }
    },[])

    const previewProjectDetails = [
        {
            label: 'Request Title',
            value: formValues?.name || ' - '
        },
        {
            label: 'RFx ID number',
            value: formValues?.rfq_id_number || ' - '
        },
        {
            label: 'Type',
            value: formValues?.type ? props?.rfqTypes?.find((type) => type.value === formValues?.type)?.label : ' - '
        },
        {
            label: 'Address',
            value: formValues?.address?.value || "-"
        },
        {
            label: 'Lead Contact Name',
            value: formValues?.lead_contact_name || "-"
        },
        {
            label: 'Phone Number',
            value: formValues?.phone_number || "-"
        },
        {
            label: 'Email',
            value: formValues?.email || "-"
        },
        {
            label: 'Description',
            value: formValues?.description || "-"
        },
        {
            label: 'Client',
            value: formValues?.client || "-"
        },
        {
            label: 'Deadline Date and Time',
            value: formValues?.deadline_date
            ?` ${moment(
                moment(formValues?.deadline_date).format("YYYY-MM-DD") +
                  " " +
                  formValues?.deadline_time +
                  ":00"
              ).format("YYYY-MM-DD - h:mm A")} ${formValues?.submission_deadline_timezone}`
            : "-",
        },
        {
            label: 'Scope of Service',
            value: formValues?.escope_of_service || "-"
        }
    ]

    return (
        <div>
            <Drawer
                placement={"right"}
                isOpen={isOpen}
                onClose={onClose}
                size="xl"
                {...props}
                className="bg-white p-11"
            >
                <DrawerOverlay onClick={onClose} />
                <DrawerContent maxWidth={'1280px'}>
                    <DrawerHeader>
                        <Flex justifyContent="space-between" align={'center'}>
                            <div>
                                <p className="text-lg text-text-primary-900 font-semibold">RFx Preview</p>
                            </div>
                            <CloseButton onClose={onClose} />
                        </Flex>
                    </DrawerHeader>
                    <DrawerBody >
                        <Box className='min-h-80 w-full border rounded-md shadow-xl grid content-between px-6 py-10'>
                            <TabHeader
                                noDivider={true}
                                heading={`${rfqData?.project?.name || ' - '} | ${rfqData?.supplierList?.name || ' - '}`}
                                description={formValues?.description || ' - '}
                            />
                            <div id='content-title'>
                                <div id='content-wrapper' className='border-t pt-5'>
                                    <Flex justifyContent="space-between">
                                        <div className='w-1/3'>
                                            <p className='text-base font-normal' >
                                                Last Updated
                                            </p>
                                            <p className='text-base font-semibold text-text-primary-900' >
                                                {moment(rfqData?.lastUpdated).format('DD MMM YYYY') || ' - '}
                                            </p>
                                        </div>
                                        <div className='w-1/3' >
                                            <p className='text-base font-normal ' >
                                                Location
                                            </p>
                                            <p className='text-base font-semibold text-text-primary-900' >
                                                {formValues?.address?.value || ' - '}
                                            </p>
                                        </div>
                                        <div className='w-1/3'>
                                            <p className='text-base font-normal' >
                                                Company Size
                                            </p>
                                            <p className='text-base font-semibold text-text-primary-900' >
                                               {companySize}
                                            </p>
                                        </div>
                                    </Flex>
                                </div>
                            </div>
                        </Box>
                        <div className='my-6'>
                            <PreviewRFQButtonTab 
                                setCurrentTab={setCurrentTab} 
                                currentTab={currentTab} 
                                formValues={formValues} 
                            />
                        </div>
                        <div>
                            {currentTab === constant.PROJECT_DETAILS && 
                                <Box className='min-h-80 w-full border rounded-md shadow-xl grid p-10 pt-6'>
                                    {previewProjectDetails.map((detail,index) => (
                                        <div className='my-3'>
                                            <p className='text-lg font-semibold text-text-secondary-500' >
                                                {detail.label}
                                            </p>
                                            <p 
                                                className='text-base font-semibold text-text-primary-900 
                                                    [&>ol]:!list-decimal [&>ul]:!list-disc [&>ol]:ml-5 [&>ul]:ml-5'
                                                dangerouslySetInnerHTML={{__html: detail?.value}} 
                                            />

                                        </div>
                                    ))}
                                </Box>
                            }
                            {currentTab === constant.QUESTIONNAIRE &&
                                <Formik initialValues={[]} >
                                    <Form>
                                        <Questionnaire questionnaire={formValues.sections} disable={true} />
                                    </Form>
                                </Formik>
                            }
                            {currentTab === constant.TENDER_COST &&
                                <div>
                                    <Formik
                                        initialValues={initialDataQuote}
                                    >
                                        <Form>
                                            <TenderCost 
                                                tables={formValues?.quote} 
                                                unitMeasures={unitMeasures} 
                                                disable={true}
                                                quoteFile={formValues?.quote_file instanceof File 
                                                    ? formValues?.quote_file 
                                                    : typeof formValues?.quote_file === 'string' ? {name:formValues?.quote_file, path:formValues?.quote_file_path}
                                                    : null
                                                }
                                                quoteType={formValues?.quote_type} 
                                            />
                                        </Form>
                                    </Formik>
                                </div>
                            }
                            {currentTab === constant.DOCUMENTATION &&
                                <Documentation documentAttachments={documentAttachments} documentDescription={formValues.documentation_description} />
                            }
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    )
}

export default PreviewRFQ

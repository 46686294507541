import React, { useState } from "react";
import { Formik, Form } from "formik";
import { showToast } from "../../utils/helpers"; // Import showToast from helpers utils
import { Divider } from "../Divider";
import {
  ViewRequestFormStyled,
  PlanSection,
  OpportunitySection,
} from "./styled";
import { FormHeading } from "../FormHeading";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { TextArea } from "../FormInputs/TextArea";
import { Submit } from "../FormInputs/Submit";
import { Button } from "../Button";

export const ViewRequestForm = ({
  accessRequest,
  current_company,
  handleSubmit,
  profileTypeId,
  title,
  ...props
}) => {
  const initialValues = {
    id: accessRequest?.id || "",
    approval_reason: "",
    is_approved: 0,
    is_viewed: 1,
    request_company_id: current_company.id,
  };
  const [decliningRequest, setDecliningRequest] = useState(false);

  return (
    <ViewRequestFormStyled>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const { isSubmitting, setFieldValue, values } = props;
          const onSubmitValidationError = () => {
            showToast("Please review field errors in form.", "Error");
          };
          return (
            <Form>
              <div className="container">
                <FormHeading
                  color={"#263859"}
                  margin={"18px 0px 30px 0px"}
                  text={title}
                />
                <PlanSection>
                  <div className="label">
                    <p>Business Name</p>
                    <p>Name of Requestor</p>
                    <p>Date</p>
                    <p>Purpose</p>
                  </div>
                  <div className="details">
                    <p>{accessRequest?.client_company?.name || <>&mdash;</>}</p>
                    <p>{accessRequest?.requestor_name || <>&mdash;</>}</p>
                    <p>{accessRequest?.request_date || "--/--/--"}</p>
                    <p>{accessRequest?.purpose}</p>
                  </div>
                </PlanSection>
                <OpportunitySection>
                  <h4>Project Opportunity</h4>
                  <div className="flex-box">
                    <p>{accessRequest?.opportunity}</p>
                    <div>
                      <Submit
                        type="submit"
                        fontSize={"14px"}
                        isSubmitting={isSubmitting && values.is_approved}
                        onClick={() => setFieldValue("is_approved", 1)}
                        text="APPROVE ACCESS"
                        place
                        submittingText="requesting..."
                      />
                      <p className="modal-footer">
                        <span>*</span> Access will be granted for 90 days
                      </p>
                    </div>
                  </div>
                </OpportunitySection>
                <Divider top="24px" bottom="24px" />

                <div className="doc-reason">
                  <TextArea
                    label="Reason for declining access (Optional)"
                    name="approval_reason"
                    placeholder={"Let them know why you are declining access"}
                    className="col xl-12"
                    placeholderSize={"1rem"}
                    errorBottom={"5px"}
                  />
                </div>
                <div className="modal-buttons">
                  <Button
                    btntype={"secondary-danger"}
                    size={"sm"}
                    type="button"
                    width={"190px"}
                    disabled={decliningRequest}
                    margin={"0px 24px 0px 0px"}
                    padding={"13px 10px !important"}
                    textfont={"14px"}
                    boxshadow={false}
                    onClick={(setErrors) => {
                      handleSubmit(values, setErrors);
                      setDecliningRequest(true);
                    }}
                  >
                    <span>
                      {decliningRequest ? "declining..." : "decline access"}
                    </span>
                  </Button>
                </div>
              </div>
              <OnSubmitValidationError callback={onSubmitValidationError} />
            </Form>
          );
        }}
      </Formik>
    </ViewRequestFormStyled>
  );
};

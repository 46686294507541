import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { Icon } from 'assets/icons/Icon';
import { cn } from 'utils/helpers';

export const CustomToastV2 = ({ success, closeToast, title, subtitle }) => (
  <div className='bg-white p-4 rounded-md flex justify-between h-max gap-3 z-50 pl-2'>
    <div className='flex gap-3'>
      <Icon icon={success === true ? "toast-success" : "toast-error"} className='-mt-2' />
      <div>
        <div className={cn('font-roboto font-semibold text-sm', success ? 'text-royal-blue' : 'text-red-600')}>
          {title || (success ? 'Success' : 'Error')}
        </div>
        {subtitle && <div className='text-text-tertiary-600 font-roboto font-normal text-sm '>{subtitle}</div>}
      </div>
    </div>
   
    <div>
      <Icon icon="toast-close" className="cursor-pointer pt-2" onClick={closeToast} />
    </div>
  </div>
);
import { Button } from "components/Button";
import React, { useEffect } from "react";
import { ReactComponent as Download } from "./../../icons/download.svg";
import { ReactComponent as Copy } from "./../../icons/copy.svg";
import  { styled }  from "styled-components";
import { Heading } from "components/Heading";
import { showToast } from "utils/helpers";
import { getBackupCodes } from "actions/authActions";
import { Skeleton } from "@chakra-ui/react";

const TwoFactorComponent = styled.div`
  .twofactor-container {
    padding: 24px 0px 24px 48px;
  }
  .twofactor-title {
    font-weight: 500;
    color: #344054;
    margin-bottom: 4px !important;
  }
  .step-box {
    padding: 24px;
    border: solid 1px #d0d5dd;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: 8px;
    margin: 20px 0;
    .step-header {
      margin: 2px 0 !important;
      font-size: 14px;
      font-weight: 400;
    }
    h4 {
      margin: 0 0 8px 0 !important;
      font-size: 16px;
      font-weight: 700;
      color: #344054;
    }
  }
  .qr-container {
    display: flex;
    justify-content: center;
    padding: 24px;
  }
  .separator-text,
  .non-separator-text {
    display: flex;
    justify-content: center;
    border: 1px solid #d0d5dd;
    width: 100%;
    height: 1px;
    margin: 18px 0 30px 0;
    p {
      margin-top: -16px;
      padding: 4px 8px;
      background-color: white;
      font-size: 14px;
      height: 18px;
    }
  }
  .non-separator-text {
    border: none;
  }
  .copy-field {
    padding: 0px 0 0 12px;
    display: flex;
    border: 1px solid #d0d5dd;
    align-items: center;
    border-radius: 8px;
    span {
      width: 100%;
      border-right: 1px solid #d0d5dd;
      height: 40px;
      line-height: 3;
      color: #d92d20;
    }
    button {
      cursor: pointer;
      min-width: 48px;
      border: none;
      background-color: transparent;
      margin-top: 2px;
      height: 20px;
    }
  }
  .enabled-button {
    background-color: white !important;
    border: 1px solid #d0d5dd !important;
    box-shadow: 0px 1px 2px 0px #1018280d;
    color: #00976b !important;
  }
  .backup-code-box{
    padding: 12px 24px;
    margin: 24px auto;
    border: 1px solid #E5E9ED;
    border-radius: 8px;
    max-width: 405px;
    
    .backup-codes-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      .code-character {
        text-align: center;
      }
  }
  .button-action-container {
    display: flex;
    width: 100%;
    border: 1px solid #E5E9ED;
    margin: 24px auto;
    border-radius: 8px;
    overflow: hidden;
    button{
      width: 100%;
      outline: none;
      border: none;
      background-color: transparent;
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      padding: 12px 0;
      font-weight: 500;
      cursor: pointer;
      &:hover{
        background-color: #F7F9FA;
    }
      svg{
        width: 20px;
        height: 20px;
        path{
          stroke: black !important;
        }
      }
    }
  }
  }
`;

const TwoFactorFinish = ({ continueHandler }) => {

  const [savedBackupCodes,setSavedBackupCodes] = React.useState(false);
  const [backupCodes,setBackupCodes] = React.useState(null);
  const [loadBackupCodes,setLoadBackupCodes] = React.useState(false);

  useEffect(() => {
    setLoadBackupCodes(true);
    getBackupCodes().then((data) => {
      setBackupCodes(data);
      setLoadBackupCodes(false);
    }).catch((error) => {
      showToast('Error getting backup codes', 'Error');
      setLoadBackupCodes(false);
    });
  }, []);
  
  const copyHandler = () => {
    navigator.clipboard.writeText(backupCodes?.join("\n")).then(() => {
      showToast("Copied to clipboard", "Success", true);
    });
  };
  const downloadHandler = () => {
    const element = document.createElement("a");
    const fileContent = backupCodes?.join("\n");
    const file = new Blob([fileContent], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "backup-codes.txt";
    document.body.appendChild(element);
    element.click();
  }

  const clickHandler = () => {
    savedBackupCodes ? continueHandler() : setSavedBackupCodes(true);
  }

  return (
    <TwoFactorComponent className="twofactor-container">
      <Heading marginBottom="40px">
        <span>{savedBackupCodes ? 'Two-Factor' : 'Save Your'} </span>
        <span>{savedBackupCodes ? "Authentication (2FA) setup successful" : "Backup Codes"}</span>
      </Heading>
        <p>
          { savedBackupCodes ?
            `From now on whenever you sign in to your Procuracon account you’ll need
            to enter both your password and an authentication code generated by your
            authenticator app.`
            :
            `Save this emergency backup code and store it somewhere safe. 
            If you lose your device this code can be used to gain access to your procuracon account.`
          }
        </p>

        {!savedBackupCodes && <div className="backup-code-box">
          <div className="backup-codes-container">
            {loadBackupCodes ?
              [...Array(15)].map((_, index) => (
                <div key={index} className="code-character">
                  <Skeleton width='90%' height='24px' startColor="#EAECF0" style={{margin : '12px auto'}} />
                </div>
              ))
            :
              backupCodes?.map((code, index) => (
                <div key={index} className="code-character">
                  <p>{code}</p>
                </div>
              ))
            }
          </div>
          <div 
            className="button-action-container" 
            style={{opacity: !backupCodes ? 0.7 : 1, pointerEvents: !backupCodes ? 'none' : 'auto'}}
          >
            <button 
              style={{borderRight : '1px solid #E5E9ED'}}
              onClick={copyHandler}
              disabled={!backupCodes}
            >
              <Copy />
              Copy
            </button>
            <button onClick={downloadHandler} disabled={!backupCodes}>
              <Download style={{height:'18px', width:'18px'}} />
              Download
            </button>
          </div>
        </div>}

      <Button
        onClick={clickHandler}
        style={{ width: "100%", margin: "32px 0" }}
        disabled={loadBackupCodes}
        disabledStyle={loadBackupCodes && !savedBackupCodes}
      >
        {savedBackupCodes ? 'Continue' : 'I have saved my backup code'}
      </Button>
    </TwoFactorComponent>
  );
};

export default TwoFactorFinish;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { navigate } from "@reach/router";
import { TabHeader } from "components/TabHeader";
import { Flex, Spinner } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import TabsComp from "components/RFQ/TabsComp/TabsComp";
import JumbotronComp from "./JumbotronComp";
import { Button } from "components/RFQ/Button";
import MenuBarComp from "./MenuBarComp";
import DocumentationComp from "./DocumentationComp";
import PreviewOnlyComp from "components/RFQ/PreviewOnlyComp/PreviewOnlyComp";

import AddendumsComp from "./AddendumsComp";
import { BackButton } from "components/RFQ/BackButton";
import {
  downloadAllDocumentation,
  getSubmission,
  saveSubmissionRFQ,
} from "actions/RFQ/Supplier/rfqActions";
import ReturnableSchedulesComp from "./ReturnableSchedulesComp";
import {
  STATUS_ACCEPTED,
  STATUS_DECLINE,
  STATUS_IN_PROGRESS,
  STATUS_INVITED,
} from "utils/constants";
import { AcceptDeclineModal } from "./AcceptDeclineModal";
import { Input } from "components/RFQ/Input";
import { getDocumentType, getUnitMeasures } from "actions/RFQ/rfqsAction";
import { SkeletonBase } from "components/Skeleton";
import YourQuoteComp from "pages/RFQSubmission/RFQPreview/YourQuoteComp";
import {
  allValidationSchema,
  initialQuoteValue,
  initialReturnableValue,
  valuesPayload,
} from "./RFQSubmissionUtils";
import { Form, Formik } from "formik";
import { SubmitModal } from "./SubmitModal";
import {
  downloadAllDocumentationGuest,
  getSubmissionGuest,
  saveSubmissionGuestRFQ,
} from "actions/guestflow/guestRfqActions";
import { getAddendumServices } from "services/supplier-flow/adendumServices";
import { getAddendumServices as getAddendumServicesGuest } from "services/guest-flow/addendumServices";
import { useSelector } from "react-redux";
import { NotFound } from "routes/NotFound";
import NoBorderButton from "components/RFQ/NoBorderButton/NoBorderButton";
import { cn } from "utils/helpers";

const RFQPreview = ({ id, isGuest }) => {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [listQuoutes, setListQuotes] = useState([]);
  const [listReturnable, setListReturnable] = useState([]);
  const formRef = useRef(null);
  const [savedReturnableValue, setSavedReturnableValue] = useState(null);
  const [savedQuoteValue, setSavedQuoteValue] = useState(null);
  const [errors, setErrors] = useState();
  const [validForm, setValidForm] = useState(true);
  const [touchedForm, setTouchedForm] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isInvited = submissionStatus === STATUS_INVITED;
  const isAccepted =
    submissionStatus === STATUS_ACCEPTED ||
    submissionStatus === STATUS_IN_PROGRESS;
  const isDecliend = submissionStatus === STATUS_DECLINE;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [unitMeasures, setUnitMeasures] = useState(null);
  const [documentCategories, setDocumentCategories] = useState([]);
  const [documentFilter, setDocumentFilter] = useState(null);
  const guestEmail = useSelector((state) => state.auth.guestUser.email) || null;
  const {
    documents,
    resources,
    pastProjects,
    isRequesting,
    requestingResources,
    loadingPastProjects,
  } = useSelector((state) => state?.companyProfile);
  const isLoadedCompanyProfile = isGuest ? true
    : !isRequesting && !requestingResources && !loadingPastProjects;

  let hasDocuments = documents?.length > 0;
  let hasResources = resources?.length > 0;
  let hasPasProjects = pastProjects?.length > 0;
  const companyId = localStorage.getItem("company_id");
  const rfqId = id;
  const [rfqCompanyId, setRFQCompanyId] = useState("");
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(null);
  const [data, setData] = useState();
  const [addendumData, setAddendumData] = useState([]);
  const [totalAddendum, setTotalAddendum] = useState(0);
  const [isNotFound, setIsNotFound] = useState(false);
  const [currentTab, setCurrentTab] = useState("Documentation");
  const [enableTabValidation, setEnableTabValidation] = useState(false);
  const [tabMenu, setTabMenu] = useState([
    {
      id: "documentation",
      name: "Documentation",
      showBadge: true,
      total: 0,
      isValid: true,
    },
    {
      id: "returnableSchedules",
      name: "Returnable Schedules",
      showBadge: true,
      total: 0,
      isValid: false,
    },
    {
      id: "yourQuote",
      name: "Quote",
      showBadge: true,
      total: 0,
      isValid: false,
    },
    {
      id: "addendums",
      name: "Addendums",
      showBadge: true,
      total: 0,
      isValid: false,
    },
  ]);
  const changeActiveTab = (tab) => {
    formRef?.current?.setErrors({});
    setCurrentTab(tab);
  };

  const getAddendum = async () => {
    let res = isGuest
      ? await getAddendumServicesGuest(rfqId, guestEmail)
      : await getAddendumServices(rfqId);
    if (res.status === 200) {
      setTotalAddendum(res?.data?.data.length);
      setAddendumData(res?.data?.data);
    }
  };

  useEffect(() => {
    if (submissionStatus) {
      if (
        submissionStatus?.toUpperCase() === "INPROGRESS" ||
        submissionStatus?.toUpperCase() === "ACCEPTED"
      ) {
        setEnableTabValidation(true);
      }
    }
  }, [submissionStatus]);

  useEffect(() => {
    setIsLoading(true);
    getAddendum();
    try {
      (isGuest
        ? getSubmissionGuest(rfqId, guestEmail)
        : getSubmission(rfqId, companyId)
      ).then((res) => {
        if (!res) {
          setIsNotFound(true);
        }
        const {
          submissionStatus,
          quoteData,
          returnableData,
          rfqDetail,
          rfqCompanyId,
        } = res;
        if (submissionStatus?.toLowerCase() === "decline") {
          isGuest
            ? navigate(".")
            : navigate(`/account/${companyId}/request-for-quote`);
        } else {
          setRFQCompanyId(rfqCompanyId);
          setSubmissionStatus(submissionStatus);
          setData(rfqDetail);
          setSavedQuoteValue(initialQuoteValue(quoteData));
          if (isLoadedCompanyProfile) {
            setSavedReturnableValue(
              initialReturnableValue(
                returnableData,
                isGuest,
                hasDocuments,
                hasPasProjects,
                hasResources
              )
            );
          }
          setListQuotes(quoteData);
          setListReturnable(returnableData);
          getDocumentType().then((res) => {
            if (res) {
              setDocumentCategories([
                { value: null, label: "All Categories" },
                ...res.map((category) => ({
                  value: category?.id,
                  label: category?.name,
                })),
              ]);
            }
          });
          getUnitMeasures().then((res) => {
            if (res) {
              setUnitMeasures(
                res.map((unit) => ({ value: unit?.id, label: unit?.name }))
              );
            }
          });

          if (isLoadedCompanyProfile) setIsLoading(false);
        }
      });
    } catch {
      setIsNotFound(true);
    }

    //eslint-disable-next-line
  }, [isLoadedCompanyProfile]);

  useEffect(() => {
    let tempTab = [...tabMenu];
    if (data) {
      tempTab[0].total = data?.documentationDocument?.length || 0;
      tempTab[1].total = listReturnable?.length || 0;
      tempTab[2].total = listQuoutes?.length || 0;
      tempTab[3].total = totalAddendum;
      setTabMenu(tempTab);
    }
    //eslint-disable-next-line
  }, [data, listQuoutes, listReturnable, totalAddendum, isLoading]);

  const downloadAllHandler = () => {
    setDownloadLoading(true);
    (isGuest
      ? downloadAllDocumentationGuest(rfqId, guestEmail)
      : downloadAllDocumentation(rfqId)
    ).then((res) => {
      if (res) {
        setDownloadLoading(false);
      } else {
        setDownloadLoading(false);
      }
    });
  };

  const saveHandler = useCallback(
    async () => {
      setLoadingSave(true);
      return new Promise((resolve, reject) => {
        (isGuest
          ? saveSubmissionGuestRFQ(
              guestEmail,
              rfqId,
              valuesPayload(formRef?.current?.values, listReturnable, isGuest)
            )
          : saveSubmissionRFQ(
              rfqId,
              valuesPayload(formRef?.current?.values, listReturnable)
            )
        )
          .then((res) => {
            if (res) {
              setSavedReturnableValue(formRef?.current?.values);
              setLoadingSave(false);
              resolve(true);
            } else {
              setLoadingSave(false);
              reject(false);
            }
          })
          .catch((e) => {
            console.log(e);
            setLoadingSave(false);
            reject(false);
          });
      });
    },
    [formRef, rfqId, listReturnable, isGuest, guestEmail]
  );

  const submitHandler = useCallback(async () => {
    await formRef?.current?.submitForm();
    setTouchedForm(true);
    if (formRef?.current?.isValid) {
      setShowSubmitDialog(true);
    }
    //eslint-disable-next-line
  }, [formRef]);

  const continueTab = async () => {
    let tempTab = [...tabMenu];
    let current = tempTab.findIndex((tab) => tab.name === currentTab);
    if(current === 1 || current === 2) {
      await saveHandler();
      if (tempTab[current].isValid) {
        changeActiveTab(tempTab[current + 1].name);
      }
    }else{
      if (tempTab[current].isValid) {
        changeActiveTab(tempTab[current + 1].name);
      }
    }
  };

  const disableContinueButton = () => {
    let tempTab = [...tabMenu];
    let current = tempTab.findIndex((tab) => tab.name === currentTab);
    if (tempTab[current].isValid) {
      return false;
    } else {
      return true;
    }
  };

  const checkingIsNotEmpty = (val) => {
    if (val !== undefined && val !== null && val !== "") {
      return true;
    } else {
      return false;
    }
  };

  const checkingIsValidNumber = (value) => {
    if (checkingIsNotEmpty(value)) {
      if (parseInt(value) > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    /**
     * checking if the returnable and quote is valid when status is INPROGRESS
     */
    let tempTabMenu = [...tabMenu];
    let returnableIsValid = true;
    let quoteIsValid = true;
    if (submissionStatus?.toUpperCase() === "INPROGRESS") {
      for (let a = 0; a < listReturnable.length; a++) {
        for (let b = 0; b < listReturnable[a]?.question?.length; b++) {
          if (listReturnable[a]?.question[b]?.required) {
            if (listReturnable[a]?.question[b]?.rfqAnswer) {
              let currentValue =
                listReturnable[a]?.question[b]?.rfqAnswer?.answer?.value;
              returnableIsValid = checkingIsNotEmpty(currentValue);
              if (!returnableIsValid) {
                break;
              }
            }
          }
        }
        if (!returnableIsValid) {
          break;
        }
      }
      for (let a = 0; a < listQuoutes?.length; a++) {
        for (let b = 0; b < listQuoutes[a]?.items?.length; b++) {
          let currentQty =
            listQuoutes[a]?.items[b]?.rqfQuoteAnswer?.answer?.value?.quantity;
          let currentRate =
            listQuoutes[a]?.items[b]?.rqfQuoteAnswer?.answer?.value?.rate;
          let currentUnit =
            listQuoutes[a]?.items[b]?.rqfQuoteAnswer?.answer?.value?.unit;
          let isQTYValid = checkingIsValidNumber(currentQty);
          let isCurrentRateValid = checkingIsValidNumber(currentRate);
          let isCurrentUnitValid = checkingIsNotEmpty(currentUnit);
          if (!isQTYValid || !isCurrentRateValid || !isCurrentUnitValid) {
            quoteIsValid = false;
            break;
          }
        }
        if (quoteIsValid === false) {
          break;
        }
      }
    }

    if (submissionStatus?.toUpperCase() === "ACCEPTED") {
      returnableIsValid = false;
      quoteIsValid = false;
      quoteIsValid = false;
    }
    tempTabMenu[1].isValid = returnableIsValid;
    tempTabMenu[2].isValid = quoteIsValid;
    tempTabMenu[3].isValid = quoteIsValid;
    setTabMenu(tempTabMenu);
    //eslint-disable-next-line
  }, [submissionStatus, listReturnable, listQuoutes]);

  const ButtonActions = ({ isBottom }) => {
    return isLoading ? (
      <div className="flex justify-end gap-3 mt-4">
        <SkeletonBase width="128px" height="46" />
        <SkeletonBase width="128px" height="46" />
      </div>
    ) : !isDecliend ? (
      <TabHeader
        noDivider={true}
        icon={
          !isBottom && (
            <BackButton handleBackButtonClick={() => navigate(".")} />
          )
        }
      >
        {isAccepted ? (
          <Flex gap={3} className={cn('items-center',isBottom && "py-8") }>
            {loadingSave ? (
              <Spinner />
            ) : (
              <NoBorderButton
                onClick={() => saveHandler()}
                text="Save"
                className="bg-white text-text-tertiary-600 text-[14px] font-roboto font-medium"
              />
            )}
            {currentTab !== "Addendums" && (
              <Button
                type="button"
                btntype="secondary"
                disabled={loadingSave || disableContinueButton()}
                onClick={() => continueTab()}
              >
                Continue
              </Button>
            )}
            <Button
              type="button"
              onClick={() => submitHandler(currentTab)}
              disabled={
                currentTab !== "Addendums" ||
                loadingSave ||
                (!validForm && touchedForm)
              }
            >
              Submit Response
            </Button>
          </Flex>
        ) : (
          isInvited && (
            <MenuBarComp
              setShowDialog={setShowDialog}
              className={isBottom && "py-8"}
            ></MenuBarComp>
          )
        )}
      </TabHeader>
    ) : (
      !isBottom && (
        <TabHeader
          noDivider={true}
          icon={<BackButton handleBackButtonClick={() => navigate(".")} />}
        />
      )
    );
  };

  const checkingErrors = () => {
    let tempTabMenu = [...tabMenu];
    if (errors?.question_answer) {
      tempTabMenu[1].isValid = false;
    } else {
      tempTabMenu[1].isValid = true;
    }
    if (errors?.quote_answer) {
      tempTabMenu[2].isValid = false;
      tempTabMenu[3].isValid = false;
    } else {
      tempTabMenu[2].isValid = true;
      tempTabMenu[3].isValid = true;
    }
    setTabMenu(tempTabMenu);
  };

  useEffect(() => {
    if (
      errors?.question_answer === undefined &&
      errors?.quote_answer === undefined
    ) {
      if (currentTab !== "Documentation") {
        checkingErrors();
      }
    } else {
      checkingErrors();
    }
    //eslint-disable-next-line
  }, [errors]);

  const isQuote = currentTab === "Your Quote";
  const isReturnable = currentTab === "Returnable Schedules";

  return isNotFound ? (
    <NotFound />
  ) : (
    <div>
      <ButtonActions
        isLoading={isLoading}
        isAccepted={isAccepted}
        isDecliend={isDecliend}
        setShowDialog={setShowDialog}
        saveHandler={saveHandler}
        currentTab={currentTab}
      />
      <div
        id="layout-dashboard"
        className="mt-8"
      >
        <JumbotronComp
          data={data}
          isLoading={isLoading}
          submissionStatus={submissionStatus}
        />
        <Flex id="RFx-container" className="mt-10 mb-10 justify-between items-center max-h-14 h-14">
          <TabsComp
            menus={tabMenu}
            activeTab={currentTab}
            changeActiveTab={changeActiveTab}
            isLoading={isLoading}
            enableValidation={enableTabValidation}
          />
          {!isLoading ? (
            currentTab === "Documentation" && (
              <Flex align={"center"} style={{ gap: "8px" }}>
                {isAccepted && (
                  <Input
                    type="select"
                    name="category"
                    placeholder="Refine by Category"
                    onChange={(e) => setDocumentFilter(e.value)}
                    value={documentFilter}
                    options={documentCategories}
                    className="min-w-[280px]"
                    disabled={true}
                  />
                )}
                <Button
                  btntype="secondary"
                  className="!h-[56px] !min-w-[180px]"
                  disabled={!isAccepted || downloadLoading}
                  onClick={downloadAllHandler}
                >
                  {!downloadLoading ? (
                    <Flex align={"center"} gap={2}>
                      <Icon icon="download" color="#0031DD" />
                      Download All
                    </Flex>
                  ) : (
                    <Spinner size="md" />
                  )}
                </Button>
              </Flex>
            )
          ) : (
            <Flex gap={2}>
              <SkeletonBase width="240px" height="56px" />
              <SkeletonBase width="160px" height="56px" />
            </Flex>
          )}
        </Flex>
        <div className="relative">
          {currentTab === "Documentation" && (
            <>
              {!isLoading ? (
                data && (
                  <DocumentationComp
                    data={data?.documentationDocument}
                    rfqId={rfqId}
                    documentFilter={documentFilter}
                  />
                )
              ) : (
                <>
                  <SkeletonBase
                    startColor="lightgray"
                    width="100%"
                    height="72px"
                    className="my-4 !rounded-lg"
                  />
                  <SkeletonBase
                    startColor="lightgray"
                    width="100%"
                    height="72px"
                    className="!rounded-lg"
                  />
                </>
              )}
            </>
          )}

          {savedReturnableValue &&
            savedQuoteValue &&
            isLoadedCompanyProfile && (
              <Formik
                initialValues={{ ...savedReturnableValue, ...savedQuoteValue }}
                innerRef={formRef}
                validationSchema={allValidationSchema(
                  listReturnable,
                  listQuoutes,
                  isGuest,
                  hasDocuments,
                  hasPasProjects,
                  hasResources,
                  isQuote,
                  isReturnable
                )}
                validateOnMount={false}
                onSubmit={() => true}
              >
                {({ isValid, errors }) => {
                  if (isValid !== validForm) {
                    setValidForm(isValid);
                  }
                  setErrors(errors);
                  return (
                    <Form>
                      {currentTab === "Returnable Schedules" && (
                        <ReturnableSchedulesComp
                          touchedForm={touchedForm}
                          questionnaire={listReturnable}
                          rfqId={rfqId}
                          savedReturnableValue={savedReturnableValue}
                          isGuest={isGuest}
                        />
                      )}

                      {currentTab === "Your Quote" && (
                        <YourQuoteComp
                          listQuoutes={listQuoutes}
                          unitMeasures={unitMeasures}
                        />
                      )}
                    </Form>
                  );
                }}
              </Formik>
            )}
          {currentTab === "Addendums" && addendumData && (
            <AddendumsComp
              rfqId={rfqId}
              rfqCompanyId={rfqCompanyId}
              addendumData={addendumData}
              isGuest={isGuest}
              guestEmail={guestEmail}
            />
          )}
          {!isAccepted && !isLoading && (
            <>
              <div
                style={{
                  background: `rgba(255,255,255,0.6)`,
                  zIndex: "333",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <div className="w-max sticky top-[80%] left-1/2 -translate-x-1/2 py-[48px]">
                  <PreviewOnlyComp submissionStatus={submissionStatus} />
                </div>
              </div>
            </>
          )}
        </div>

        <ButtonActions
          isBottom
          isLoading={isLoading}
          isAccepted={isAccepted}
          isDecliend={isDecliend}
          setShowDialog={setShowDialog}
        />

        <AcceptDeclineModal
          showDialog={showDialog}
          onClose={() => setShowDialog(null)}
          rfqId={rfqId}
          setSubmissionStatus={setSubmissionStatus}
          guestEmail={guestEmail}
          isGuest={isGuest}
          companyId={companyId}
        />

        <SubmitModal
          showDialog={showSubmitDialog}
          onClose={() => setShowSubmitDialog(false)}
          rfqId={rfqId}
          companyId={companyId}
          saveSubmissionHandler={saveHandler}
          isGuest={isGuest}
          guestEmail={guestEmail}
        />
      </div>
    </div>
  );
};

export default RFQPreview;

import { Flex } from '@chakra-ui/core'
import { Button } from 'components/Button'
import { TabHeader } from 'components/TabHeader'
import React, { useEffect, useState } from 'react'
import { ProjectStyled } from './styled'
import { Link } from "@reach/router";
import FilterButton from 'components/FilterButton/FilterButton'
import ButtonViewGroup from 'components/ButtonViewGroup/ButtonViewGroup'
import GridViewProjects from './GridViewProjects'
import ListViewProjects from './ListViewProjects'
import { FilterProject } from 'components/RFQ/FilterProject'
import { useDisclosure } from '@chakra-ui/core'
import { getProjects, getFavoriteProjects } from 'actions/RFQ/projectsAction'
import { connect } from 'react-redux'
import SearchBar from 'components/RFQ/SearchBar/SearchBar'
import BadgeComp from 'components/RFQ/BadgeComp/BadgeComp'
import SortComp from 'components/RFQ/SortComp/SortComp'
import { useDispatch } from 'react-redux'
import { SET_PROJECT_BADGES_COUNT } from 'actions/RFQ/actionTypes'
import { useSelector } from 'react-redux'
import { Icon } from 'assets/icons/Icon'



const sortedData = [
    {
        name: 'Sort Alphabetically (A to Z)',
        value: 'asc',
        sort: 'Sort by: (A to Z)'
    },
    {
        name: 'Sort Alphabetically (Z to A)',
        value: 'desc',
        sort: 'Sort by: (Z to A)'
    },
    {
        name: 'Sort by Last Activity',
        value: 'last_activity',
        sort: 'Sort by Last Activity'
    },
    {
        name: 'Sort by Date Created',
        value: 'created',
        sort: 'Sort by Date Created'
    }
]


const tabMenu = [
    {
        id: 'myProject',
        name: 'My Projects',
        showBadge: true
    },
    {
        id: 'allProject',
        name: 'All Projects',
        showBadge: true
    },
    {
        id: 'archived',
        name: 'Archived',
        showBadge: true,
    },
    {
        id: 'favourite',
        name: 'Favourites',
        showBadge: true,
    }
]



function Projects({ getProjects, getFavoriteProjects, projects, isLoading, isLoadingFavorites }) {
    const [currentTab, setCurrentTab] = useState('myProject');
    const currentPage = projects?.[currentTab]?.meta?.current_page
    const [view, setView] = useState('grid');
    const [sorted, setSorted] = useState(sortedData[2]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [openPopUp, setOpenPopUp] = useState(false);
    const [filters, setFilters] = useState(null);
    const [loadingPagination, setLoadingPagination] = useState(false);
    const [search, setSearch] = useState('');
    const projectBadgeCounts = useSelector(state => state.rfq?.projects?.projectCount);

    const dispatch = useDispatch();


    const pageChecker = (pages = 'current_page') => {
        let page = 1;
        if (JSON.stringify(projects) !== '{}') {
            page = projects?.[currentTab]?.meta?.[pages];
        }
        return page;
    };

    const [currPage, setCurrPage] = useState(pageChecker('current_page'));
    const [lastPage, setLastPage] = useState(pageChecker('last_page'));

    const onScroll = async () => {
        const { clientHeight, scrollHeight, scrollTop } = document.getElementById("element-scroll");
        if (!isLoading && !loadingPagination && scrollTop + clientHeight >= scrollHeight - 300) {
            if (currPage < lastPage) {
                setLoadingPagination(true);
                getProjects({ page: currPage + 1, search, sort: sorted.value, filters })
                setLoadingPagination(false);
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            onScroll();
        };

        const scrollableElement = document.getElementById("element-scroll");

        if (scrollableElement) {
            scrollableElement.addEventListener("scroll", handleScroll);

            return () => {
                scrollableElement.removeEventListener("scroll", handleScroll);
            };
        }
        // eslint-disable-next-line
    }, [currPage, lastPage, projects, isLoading, loadingPagination]);

    
    useEffect(() => {
        setLoadingPagination(false);
        // eslint-disable-next-line
    }, [currPage]);

    useEffect(() => {
        setCurrPage(1)
        getProjects({ page: 1, search, sort: sorted.value, filters, reset: true })

        if(!isLoadingFavorites){
            getFavoriteProjects();
        }
        //eslint-disable-next-line
    }, [search, sorted, filters, currentTab]);

    useEffect(() => {
        const currentTotal = projects?.[currentTab]?.meta?.total;
        if (currentTotal !== undefined && currentTotal !== null) {
            const updatedBadgeCounts = { ...projectBadgeCounts, [currentTab]: currentTotal };
            dispatch({ type: SET_PROJECT_BADGES_COUNT, payload: updatedBadgeCounts });
        }
        //eslint-disable-next-line
    }, [search, sorted, filters, currentTab]);


    useEffect(() => {
        setCurrPage(pageChecker('current_page'));
        setLastPage(pageChecker('last_page'));
        //eslint-disable-next-line
    }, [projects, currentPage, currentTab]);

    const getSortingProject = () => {
        setOpenPopUp(false);
    };

    return (
        <ProjectStyled>
            <TabHeader 
                heading='Projects and Assets' 
                description="Create and manage all your projects and assets here"
            >
                <Link to={`new-project`}>
                    <Button style={{maxHeight:"48px"}}>
                        <Flex  className='items-center gap-[6px]'>
                            <div className='w-5 h-5 items-center justify-center flex'>
                                <Icon icon='plus' style={{width:'12px', height:'12px', color: '#fff', fontWeight: 'bold'}} />                      
                            </div>
                            <div className='font-roboto capitalize font-semibold text-base'>
                            Add New Project / Asset
                            </div>
                        </Flex>
                    </Button>
                </Link>
            </TabHeader>
            <div id="layout-dashboard" className='space-y-9'>
                <div>
                    <Flex style={{ justifyContent: 'space-between' }}>
                        <Flex style={{ gap: '8px' }}>
                            {tabMenu.map((tbMenu, index) => {
                                return <div key={index} className='group'>
                                    <Button btntype='tab' active={currentTab === tbMenu.id ? true : false} onClick={() => setCurrentTab(tbMenu.id)}>
                                        <div style={{ fontFamily: 'roboto', fontWeight: '500' }} className={`text-${currentTab === tbMenu.id ? 'to-utility-brand-700' : 'text-text-primary-500'}`} >{tbMenu.name}</div>
                                        {tbMenu.showBadge && <BadgeComp title={tbMenu.id} active={currentTab} total={ projects?.[tbMenu.id]?.meta?.total || projectBadgeCounts[tbMenu.id] || 0}></BadgeComp>}
                                    </Button>
                                </div>
                            })}                         
                        </Flex>
                        <Flex style={{ gap: '16px' }}>
                            <SearchBar setState={setSearch} />
                            <FilterButton onClick={onOpen} filters={filters} />
                            <SortComp
                                data={sortedData}
                                sorted={sorted}
                                onClickCB={(item) => {
                                    getSortingProject(item);
                                    setSorted(item);
                                }}
                                openPopUpCB={openPopUp}
                                setOpenPopUpCB={setOpenPopUp}>
                            </SortComp>
                            <ButtonViewGroup setView={setView} view={view} />
                        </Flex>
                    </Flex>
                </div>
                <div>
                {view === 'grid' ?
                    <GridViewProjects
                        data={projects?.[currentTab]?.data}
                        currentTab={currentTab}
                        currPage={currPage}
                        isLoading={isLoading}
                    />
                    :
                    <ListViewProjects
                        data={projects?.[currentTab]?.data}
                        currentTab={currentTab}
                        currPage={currPage}
                        isLoading={isLoading}
                    />}
                </div>

            </div>
            <FilterProject setFilters={setFilters} filters={filters} isOpen={isOpen} onClose={onClose} currentTab={currentTab} />
        </ProjectStyled>
    )
}

const mapStateToProps = (state) => {
    return {
        projects: state.rfq.projects.projects,
        isLoading: state.rfq.projects.loading,
        isLoadingFavorites: state.rfq.projects.favorites.loading,
    };
};

export default connect(mapStateToProps, { getProjects, getFavoriteProjects })(Projects);


import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import { useField, useFormikContext } from "formik";

import { FormLabel } from "../TextInput";
import { Error } from "../Error";
import { theme } from "../../../theme";
import { getDocumentSubCategories } from "../../../actions/companyActions";

const animatedComponents = makeAnimated();

export const DocumentSubCategoryComponent = ({
  name,
  options,
  setFieldValue,
  defaultValue,
  label,
  required,
  values,
  getDocumentSubCategories,
  profileTypeId,
  selectedCategory,
  ...props
}) => {
  //eslint-disable-next-line
  const [field, meta] = useField(name);
  const { setFieldTouched } = useFormikContext();
  const [optionsArray, setOptionsArray] = useState([]);
  const [value, setValueState] = useState(null);
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    getDocumentSubCategories();
  }, [getDocumentSubCategories]);

  useEffect(() => {
    let _optionsArray = [];
    if (options.length) {
      if (selectedCategory === 3) {
        _optionsArray = options
          .filter((o) => o.parent_id === selectedCategory)
          .filter((o) => o.profile_types.includes(profileTypeId))
          .map((option) => ({
            value: {
              id: option.id,
              name: option.name,
            },
            label: option.name,
          }));
      } else {
        _optionsArray = options
          .filter((o) => o.parent_id === selectedCategory)
          .map((option) => ({
            value: {
              id: option.id,
              name: option.name,
            },
            label: option.name,
          }));
      }
    }

    let _value =
      _optionsArray.find(
        (option) => option.value.id === parseInt(defaultValue)
      ) ?? optionsArray[0];

    if (!_value) {
      _value = { label: field.value, value: field.value };
    }

    if (_optionsArray.length > 0) {
      setSelected(_optionsArray[0]);
    }

    setValueState(_value);
    setOptionsArray(_optionsArray);
    const initialChecker = () => {
      if (_optionsArray.length < 1) return "";
      if (defaultValue) {
        return (
          _optionsArray.find(
            (option) => option.value.id === parseInt(defaultValue)
          )?.value ?? _optionsArray[0]?.value
        );
      }
    };
    if (selectedCategory) setFieldValue(name, initialChecker());
    setSelected({ label: _value.value?.name, value: _value.value?.id });

        // eslint-disable-next-line
    }, [selectedCategory, options]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      color: state.isSelected ? "#ffffff" : theme.colors.gray,
    }),
    control: (provided) => ({
      ...provided,
      padding: "0.338rem",
      minHeight: 0,
      borderColor: "#e7eaee",
      marginBottom: "1.25rem",
      color: "green",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colors.gray,
    }),
  };

    const handleOnChange = (option) => {
        setFieldValue(name, option ? option.value : '');
        setSelected(option);
    };

  return (
    <div className={props.className}>
      <FormLabel>
        Sub Category
        {required && <span>*</span>}
      </FormLabel>

      <ReactSelect
        name={name}
        styles={customStyles}
        closeMenuOnSelect={true}
        components={{ animatedComponents, IndicatorSeparator: () => null }}
        onChange={handleOnChange}
        value={selected}
        onBlur={() => setFieldTouched(name, true)}
        options={optionsArray}
      />

      <Error meta={meta} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profileTypeId: state.search.activeCompany.profile_type.id,
    options: state.companyProfile.documentSubCategories,
  };
};

export const DocumentSubCategory = connect(mapStateToProps, {
  getDocumentSubCategories,
})(DocumentSubCategoryComponent);

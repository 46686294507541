import { navigate } from "@reach/router";
import { Button } from "components/RFQ/Button";
import React, { useEffect, useState } from "react";
import tw, { styled, css } from "twin.macro";
import { TabHeader } from "components/TabHeader";
import { UtilityButton } from "components/RFQ/UtilityButton";
import { Card, Flex, Grid, SkeletonText, useDisclosure } from "@chakra-ui/react";
import { Heading } from "components/Heading";
import StatusBadge from "components/RFQ/StatusBadge/StatusBadge";
import { AsyncImage } from "loadable-image";
import { Icon } from "assets/icons/Icon";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import { SupplierList } from "./SupplierList";
import { useDispatch } from "react-redux";
import { getProjectById, setProjectFavorite, getFavoriteProjects } from "actions/RFQ/projectsAction";
import { NotFound } from "routes/NotFound";
import { SkeletonBase } from "components/Skeleton";
import { AddSupplierListDrawer } from "components/Pages/ProjectDetail/AddSupplierListDrawer";
import BadgeComp from "components/RFQ/BadgeComp/BadgeComp";
import { BackButton } from "components/RFQ/BackButton";
import { isImageBroken } from "utils/helpers";
import { DEFAULT_IMAGE_AVATAR, DEFAULT_IMAGE_BANNER } from "utils/constants";
import { connect } from 'react-redux'

const ProjectDetailContainer = styled.div(() => [
  tw`relative overflow-auto`,
  css`
    .buttons-container {
      gap: 8px;
    }
    .container-content {
      padding-right: 2px;
      padding-left: 2px;
    }
    .img-card {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    .project-description ul {
    list-style-type: disc; 
    margin-left: 20px; 
    }

    .project-description ol {
        list-style-type: decimal; 
        margin-left: 20px; 
    }

    .project-description li {
        margin-bottom: 5px; 
    }

  `,
]);



const ProjectDetail = ({ id, archive, favorites, getFavoriteProjects, isLoadingFavorites, ...props }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [projectData, setProjectData] = useState(null)
  const [bannerImage, setBannerImage] = useState(projectData?.backgroundImagePath)
  const [avatarImage, setAvatarImage] = useState(projectData?.logoPath)
  const [supplierLists, setSupplierLists] = useState(projectData?.supplierList || [])
  const [isNotFound, setIsNotFound] = useState(false);
  const [isArchive, setIsArchive] = useState(projectData?.status.toUpperCase() === 'ARCHIVED');
  const [isFavorite, setIsFavorite] = useState(favorites.includes(parseInt(id)) || false);
  const [loadProject, setLoadProject] = useState(false);

  useEffect(() => {
    if(!isLoadingFavorites){
      getFavoriteProjects();
    }
    if (!projectData) {
      setLoadProject(true)
      getProjectById(id).then((data) => {
        if (data) {
          setProjectData(data)
          setLoadProject(false)
        }else{
          setIsNotFound(true);
        }
      }).catch(err => { setIsNotFound(true); setLoadProject(false); })
    }
    if (projectData) {
      setIsArchive(projectData?.status.toUpperCase() === 'ARCHIVED')
      setSupplierLists(projectData?.supplierList || [])
      setBannerImage(projectData?.backgroundImagePath)
      setAvatarImage(projectData?.logoPath)
    }
    if(isLoadingFavorites === 'idle'){
      setIsFavorite(favorites.includes(parseInt(id)) || false);
    }
  }, [projectData, id, favorites, isLoadingFavorites, getFavoriteProjects, dispatch]);

  const handleFavourite = () => {
    dispatch(
      setProjectFavorite({
        project_ids: [
          id
        ],
        isRemove: isFavorite
      }))
      .then(() => setIsFavorite(!isFavorite))
  }

  const checkImages = async () => {
    let isBannerBroken = await isImageBroken(projectData?.backgroundImagePath)
    let isAvatarBroken = await isImageBroken(projectData?.logoPath)
    if (isBannerBroken) {
      setBannerImage(DEFAULT_IMAGE_BANNER)
    }
    if (isAvatarBroken) {
      setAvatarImage(DEFAULT_IMAGE_AVATAR)
    }
  }

  useEffect(() => {
    checkImages();

    //eslint-disable-next-line
  }, [projectData])

  isNotFound && <NotFound />

  return (
    isNotFound
    ? <NotFound />
    : <ProjectDetailContainer {...props} >
      <TabHeader icon={<BackButton handleBackButtonClick={() => navigate("../")} />}>
        <Flex gap={4}>
          {!loadProject ?
            <>
              {!isArchive && <UtilityButton text="Edit Project" icon="edit-icon" onClick={() => navigate(`../edit-project/${id}`)} />}
            </>
            :
            <>
              <SkeletonBase width="120px" height="40px" />
              <SkeletonBase width="120px" height="40px" />
            </>
          }
        </Flex>
      </TabHeader>
      <div className="container-content">
        <Card className="rounded-2xl relative card-shadow bg-gray-50 mb-8 overflow-hidden">
          <Grid
            className="bg-white rounded-md grid-cols-12"
            justifyContent={"space-between"}
          >
            <div className="p-8 col-span-7">
              <div className="flex items-center" gap={6}>
                <div className={`rounded-full drop-shadow-md mt-2  mr-6 w-12 h-12 content-center text-center text-utility-gray-700 font-medium bg-center bg-cover bg-no-repeat`} style={{ backgroundImage: `url(${avatarImage})` }}></div>
                {!loadProject ? <Heading className="text-5xl !text-royal-blue">
                  {projectData?.name}
                </Heading>
                  :
                  <SkeletonBase height="48px" width="70%" />}
              </div>
              <div className="mt-6">
                {!loadProject ? <span className="font-roboto font-normal text-text-primary-700 project-description" style={{ fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: projectData?.description }} /> : <SkeletonText noOfLines={4} width="90%" skeletonHeight='20px' startColor="lightgray" />}
              </div>
              <div id="project-info" className="border-t mt-12 pt-6">
                {!loadProject && projectData ? <Flex className="text-base flex-col gap-4 font-semibold">
                  <Flex gap={4}>
                    <p className="font-roboto font-normal min-w-36 text-text-secondary-500" style={{ lineHeight: '24px', fontSize: '16px' }}>Project Status</p>
                    <StatusBadge status={projectData?.status} />
                  </Flex>
                  <Flex gap={4}>
                    <p className="font-roboto font-normal min-w-36 text-text-secondary-500" style={{ lineHeight: '24px', fontSize: '16px' }}>Sector</p>
                    <p className="font-roboto font-semibold text-text-primary-900" style={{ fontSize: '16px', lineHeight: '24px' }}>{projectData && projectData.sector.map((sector) => sector.name).join(", ")}</p>
                  </Flex>
                  <Flex gap={4}>
                    <p className="font-roboto font-normal min-w-36 text-text-secondary-500" style={{ lineHeight: '24px', fontSize: '16px' }}>Project Lead</p>
                    <p className="font-roboto font-semibold text-text-primary-900" style={{ fontSize: '16px', lineHeight: '24px' }}>{projectData?.projectLead}</p>
                  </Flex>
                  <Flex gap={4}>
                    <p className="font-roboto font-normal min-w-36 text-text-secondary-500" style={{ lineHeight: '24px', fontSize: '16px' }}>Project Location</p>
                    <p className="font-roboto font-semibold text-text-primary-900" style={{ fontSize: '16px', lineHeight: '24px' }}>{projectData?.location}</p>
                  </Flex>
                </Flex>
                  :
                  <Flex className="text-base flex-col gap-4 font-semibold">
                    <Flex gap={4}>
                      <SkeletonBase />
                      <SkeletonBase width="280px" />
                    </Flex>
                    <Flex gap={4}>
                      <SkeletonBase />
                      <SkeletonBase width="280px" />
                    </Flex>
                    <Flex gap={4}>
                      <SkeletonBase />
                      <SkeletonBase width="280px" />
                    </Flex>
                    <Flex gap={4}>
                      <SkeletonBase />
                      <SkeletonBase width="280px" />
                    </Flex>
                  </Flex>}
              </div>
            </div>
            <div className="col-span-5 relative">
              <AsyncImage
                src={bannerImage}
                alt="banner-card"
                className="img-card "
                loader={
                  <img
                    src={DEFAULT_IMAGE_BANNER}
                    alt="banner-card"
                    className="img-card"
                    style={{ filter: "blur(10px)" }}
                  />
                }
              />
              <Icon
                icon="favorite"
                style={{ color: isFavorite ? "#FFCD00" : "transparent", alignSelf: 'center', cursor: 'pointer', filter: "drop-shadow(0px 0px 1px #EAECF0)" }}
                className='absolute top-5 right-5 z-10'
                onClick={handleFavourite}
              />
            </div>
          </Grid>
        </Card>
        <div id="card-supplier-list">
          <Card className="rounded-md !border !border-gray-50 relative card-shadow  bg-white mb-8 p-1">
            <div id="card-container-supplier" className="bg-white">
              <div
                id="card-header-supplier"
                className="border-b py-5 px-6 flex justify-between items-center"
              >
                <Flex gap={2} className="items-center">
                  <h1 className="text-lg font-semibold text-black">
                   Supplier List(s) added to this Project
                  </h1>
                  <BadgeComp total={supplierLists?.length || 0} active={"supplierlist"} title={"supplierlist"} />
                </Flex>
                <div>
                  <Button btntype={"primary"} onClick={onOpen} style={{ width: "160px", height: '36px' }}>
                    <div className="flex items-center justify-around -ml-5 -mr-5">
                      <Icon
                        icon="plus"
                        style={{ width: "14px", height: "14px", color: '#fff', fontWeight: 'bold' }}
                      />
                      <div className="font-roboto ml-3 font-semibold" style={{ fontSize: '14px', lineHeight: '20px' }}>
                        Add Supplier List
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
              <div id="supplier-content" className=" rounded-md">
                {supplierLists.length > 0 ?
                  <div>
                    <SupplierList data={supplierLists} project={projectData} setSupplierLists={setSupplierLists} />
                  </div>
                  :
                  <div className={`place-content-center`}>
                    <NotFoundDatas 
                      text={'No Supplier Lists Found'} 
                      description={"To get started, import relevant Suppliers from your Supplier List to this project"}  
                      className="absolute -translate-x-2/4 left-2/4"
                    >
                      <Button btntype={"primary"} style={{ width: 'max-content' }} onClick={onOpen}>
                        <Flex gap={2} align={'center'}>
                          <div className='w-5 h-5 items-center justify-center flex'>
                            <Icon icon='plus' style={{width:'12px', height:'12px', color: '#fff', fontWeight: 'bold'}} />                      
                          </div>
                          Add Supplier List
                        </Flex>
                      </Button>
                    </NotFoundDatas>
                  </div>
                }

              </div>
            </div>
          </Card>
          {isOpen && <AddSupplierListDrawer projectData={projectData} defaultLists={supplierLists} isOpenContainer={isOpen} onCloseContainer={onClose} projectId={projectData?.id} setSupplierLists={setSupplierLists} />}
        </div>
      </div>

    </ProjectDetailContainer>
  );
};

const mapStateToProps = (state) => {
  return {
      favorites: state.rfq.projects.favorites.project_ids,
      isLoadingFavorites: state.rfq.projects.favorites.loading,
  };
};

export default connect(mapStateToProps, { getFavoriteProjects })(ProjectDetail);

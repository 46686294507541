import React, { useEffect, useState } from "react";
import { Formik, Form, useFormikContext, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import debounce from "lodash.debounce";
import { TextInput } from "../FormInputs/TextInput";
import { CompanySize } from "../FormInputs/CompanySize";
import { Sectors } from "../FormInputs/Sectors";
import { Speciality } from "components/FormInputs/Speciality";
import {
  submitCompanyInformationForm,
  getCompany,
} from "actions/companyActions";
import { getCurrentUser } from "actions/authActions";
import { Select } from "../FormInputs/Select";
import { ExpertiseRepeater } from "../FormInputs/ExpertiseRepeater";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { Trades } from "../FormInputs/Trades";
import { GlobalPresentMulti } from "../GlobalPresenceMulti/GlobalPresentMulti";
import { TabContent } from "../Tabs/styled";
import { formatABN, isValidAbnOrAcn } from "../../utils/helpers";
import { FlashError } from "../FormInputs/FlashError";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import _ from "lodash";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { FileUpload } from "components/RFQ/FileUpload";
import { Submit } from "components/FormInputs/Submit";
import { getGlobalPresence } from "actions/globalPresenceActions";
import {showToast as showToastFunction} from "../../utils/helpers";

const AutoSave = ({ debounceMs }) => {
  const formik = useFormikContext();
  // eslint-disable-next-line
  const debouncedSubmit = React.useCallback(
    debounce(() => {
      if(formik.isSubmitting) return;
      if(!formik.isSubmitting) formik.submitForm()
    }, debounceMs),
    [formik.submitForm,formik.isSubmitting]
  );

  useEffect(() => {
    if(!formik.isSubmitting){
      debouncedSubmit();
    }else{
      return () => debouncedSubmit.cancel();
    }
    return () => debouncedSubmit.cancel();
    
  }, [debouncedSubmit, formik.values, formik.isSubmitting]);

  return true;
};


export const CompanyInformationFormComponent = ({
  company,
  companyId,
  getCompany,
  getCurrentUser,
  btnCompanyInformation,
  getGlobalPresence,
  ...props
}) => {
  const [initialValues, setInitialValues] = useState(props.initialValues);
  const [errorStatus, setErrorStatus] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorBusinesName,setErrorBusinesName] = useState({})
  const [isErrorBusinesName,setIsErrorBusinessName] = useState(false)
  const accountStatusOptions = [
    { label: "Hidden", value: "hidden" },
    { label: "Visible", value: "published" },
  ];
  const [savedField, setSavedField] = useState(props.initialValues);
  const globalPresenceOptions = props.globalPresenceData.map((g) => {
    return {
      value: g.id,
      label: g.name,
    };
  });

  useEffect(() => {
    if (
      JSON.stringify(savedField.logo) !== JSON.stringify(initialValues.logo)
    ) {
      setInitialValues(savedField);
    }
    if (
      JSON.stringify(savedField.banner_image) !==
      JSON.stringify(initialValues.banner_image)
    ) {
      setInitialValues(savedField);
    }
  }, [savedField, initialValues, setInitialValues]);

  useEffect(() => {
    if(props.globalPresenceData.length === 0){
      getGlobalPresence();
    }
  },[props.globalPresenceData,getGlobalPresence]);

  return initialValues.name !== undefined ? (
    <Formik
      innerRef={props?.formikRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string().required().max(80, "Business name should not exceed 80 characters"),
        company_number: Yup.string(),
        sector_ids: Yup.array().min(1, "You must select at least one sector."),
        speciality_ids:
          company.profile_type.id === 3 || company.profile_type.id === 2
            ? Yup.string().required()
            : "",
        description: Yup.string().required(),
        contact_name: Yup.string().required(),
        phone: Yup.string()
          .required("* THIS FIELD IS REQUIRED")
          .matches(
            /^(?=.*)((?:\+?61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?([0-1][2-47-8])\)?) ?-?(?=.*)((\d{1} ?-?\d{3}$)|(00 ?-?\d{4} ?-?\d{4}$)|( ?-?\d{4} ?-?\d{4}$)|(\d{2} ?-?\d{3} ?-?\d{3}$))/,
            "Invalid Phone No."
          ),
        disciplines: Yup.array().when("profile_type", {
          is: (profile_type) => profile_type === 4,
          then: Yup.array().of(
            Yup.object().shape({
              expertise: Yup.string().required(),
              disciplines: Yup.array().required().min(1),
            })
          ),
        }),
        abn: Yup.string()
          .required("* THIS FIELD IS REQUIRED")
          .test(
            "test abn/acn",
            "*INVALID ABN/ACN",
            (value) => value && isValidAbnOrAcn(value)
          )
          .nullable(true),
        email: Yup.string().email().required(),
        website: Yup.string(),
        status: Yup.string().required(),
        size_type_id:
          company.profile_type.id !== 1 ? Yup.string().required() : "",
        trade_ids: company.profile_type.id === 3 ? Yup.string().required() : "",
        global_presence:
          company.profile_type.id !== 1 ? Yup.array().required() : "",
        years_trading:
          company.profile_type.id !== 1 ? Yup.number().required() : "",
        // keywords:Yup.array().required(),
      })}
      onSubmit={async (values, { setStatus }) => {
        try {
          await props.submitCompanyInformationForm(
            values.company_id,
            values,
            setSavedField
          );
          showToast && showToastFunction("Data updated successfully.", "Success", true);
          setIsErrorBusinessName(false)
          setErrorBusinesName({})
          setShowToast(false);
        } catch (e) {
        
          const error = await e.response?.json();
          setErrorStatus(true);
          setStatus(error?.message);
          if(error?.message==="The given data was invalid."){
         
          
            setIsErrorBusinessName(true)
            setErrorBusinesName(error)
            //The name has already been taken.
          }
        }
      }}
    >
      {(props) => {
        const {
          setFieldValue,
          isSubmitting,
          values,
          status,
          errors,
          setStatus,
          setErrors,
        } = props;
        const onSubmitValidationError = () => {
        
          setStatus("required");
          setErrorStatus(true);
        };
        const onMaxCharacter = () => {
          setStatus("maxCharacter");
          setErrorStatus(true);
        };

        //global presence values
        const globalPresenceInitialValues = initialValues.global_presence.map(
          (g) => {
            return {
              value: g.id,
              label: g.name,
            };
          }
        );
        let globalPresenceIds = values.global_presence?.map(
          (g) => g.id || g.value
        );
        let globalPresenceValueObj = globalPresenceOptions.filter((g) =>
          globalPresenceIds?.includes(g.value)
        );

        return (
          <Form onChange={() => setErrorStatus(false)}>
            {!isSubmitting &&
              !errorStatus &&
              !_.isEqual(savedField, values) && <AutoSave debounceMs={3000} />}
            <TabContent>
              <div style={{ marginLeft: '-46px', marginRight: '-46px' }}>
                <div className="mb-5">
                  <div className="font-roboto font-semibold text-text-primary-900 text-[18px]">Company Details</div>
                  <p className="font-roboto text-sm text-text-tertiary-600 mt-2">These are your personal details linked to your Procuracon account. Please ensure they're up-to-date.</p>
                </div>
                <div className="grid grid-cols-2 gap-6">
                {company.profile_type.id !== 1 && (
                  <div className="row">
                    <Select
                      label="Profile Visibility *"
                      name="status"
                      className="col xl-6"
                      setFieldValue={setFieldValue}
                      options={accountStatusOptions}
                      guideLine="Control when your profile is made visible or hidden to our members."
                      value={accountStatusOptions.filter((option) => {
                        return values.status === option.value;
                      })}
                    />
                  </div>
                )}
                </div>
                <div className="flex justify-between items-center gap-6 w-full">
                  <TextInput
                    icon={true}
                    iconPosition="left"
                    customIcon="briefcase"
                    label="Business Name"
                    required="true"
                    name="name"
                    type="text"
                    flexGrow="1"
                    className="flex-1"
                  />
                  <div className="flex-1">
                  <div className="mr-2 w-1/2">
                    <div className="flex justify-start items-center gap-3">
                    <label class="relative inline-flex items-center cursor-pointer">
                          <Field
                            type="checkbox"
                            name="indigenous_buisness"
                            className="sr-only peer"
                          />
                          <div class="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-transform peer-checked:after:translate-x-full"></div>
                        </label>
                      <div>
                        <LabelForm label="Indigenous Business"></LabelForm>
                        <p className="font-roboto text-sm text-text-tertiary-600 -mt-1">Are you an Indigenous Business?</p>
                      </div>
                    </div>
                  </div>
                        
                      </div>
                  
                </div>
                <div className="flex justify-between items-center gap-6 w-full">
                  <TextInput
                    label="Website"
                    name="website"
                    type="text"
                    flexGrow="1"
                    className="flex-1"
                  />
                  <TextInput
                    icon={true}
                    iconPosition="left"
                    customIcon="search-sm"
                    label="ABN/ACN"
                    placeholder="ABN/ACN"
                    name="abn"
                    type="text"
                    flexGrow="1"
                    formatValue={formatABN}
                    required="true"
                    setFieldValue={setFieldValue}
                    className="flex-1"
                  />
                </div>
                <div className="grid grid-cols-2 gap-6">
                  {company.profile_type.id !== 1 && (
                    <CompanySize
                      name="size_type_id"
                      required={company.profile_type.id !== 1}
                      value={values.size_type_id}
                      profileType={values.profile_type}
                      editForm
                      className="col-span-1"
                      guideLine="This is the size of the business relevant to your State or Territory (include all other offices located within the same State or Territory)."
                    />
                  )}
                  {company.profile_type.id !== 1 ? (
                    <TextInput
                      label="Number of Years Trading"
                      required="true"
                      name="years_trading"
                      type="number"
                      className="col-span-1"
                      guideLine="Will be updated every year"
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex justify-between items-center gap-6 w-full">
                  <FileUpload
                    label={'Company Logo'}
                    description={'We recommend uploading your logo image with dimensions of 300x300 pixels'}
                    name='logo'
                    types={['jpg', 'png']}
                    maxFileSize={10}
                    required={true}
                    setFieldValue={setFieldValue}
                    defaultFileName='Logo'
                    value={props.initialValues.logo || ""}
                  />
                  <FileUpload
                    label={'Banner Image'}
                    description={'Recommended banner image resolution is 730x400 pixels for optimal display quality.'}
                    name='banner_image'
                    types={['jpg', 'png']}
                    maxFileSize={10}
                    required={true}
                    setFieldValue={setFieldValue}
                    multi={false}
                    value={props.initialValues.logo || ""}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  {company.profile_type.id !== 1 ? (
                      <GlobalPresentMulti
                        options={globalPresenceOptions}
                        name="global_presence"
                        setFieldValue={setFieldValue}
                        label="Global Presence"
                        required={true}
                        defaultValue={globalPresenceInitialValues}
                        values={globalPresenceValueObj}
                        isClearable={true}
                        className="col-span-1"
                        sortingValue
                      />
                    ) : (
                      <></>
                    )}
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <Sectors
                    label="Relevant Sectors / Experience"
                    required="true"
                    name="sector_ids"
                    setFieldValue={setFieldValue}
                    defaultValue={initialValues.sector_ids}
                    values={values}
                    sortingValue
                    flexGrow="1"
                    className="flex-1"
                  />
                </div>
                <div className="grid grid-cols-1 w-full"> 
                  {/* consultants */}
                  {company.profile_type.id === 4 && (
                    <ExpertiseRepeater
                      label="Services provided by company"
                      name="disciplines"
                      values={values.disciplines}
                      profileType={values.profile_type}
                      required
                    />
                  )}
                </div>
                <div className="flex justify-between gap-6 w-full">
                  <div className="flex-1">
                    <FormatedInput
                      label="Company Description"
                      required="true"
                      name="description"
                      characterLimit={1500}
                      setErrors={setErrors}
                      onMaxCharacter={onMaxCharacter}
                      className="flex-1"
                      flexGrow="1"
                      guideLine=""
                      errorBottom='0px'
                    />
                    <p className="font-roboto text-sm text-text-tertiary-600 -mt-3 mb-4">Tell us about your business and let clients know your vision, mission and values.</p>
                  </div>
                </div>
                <div className="grid grid-cols-1 w-full">
                  {(company.profile_type.id === 2 ||
                    company.profile_type.id === 3) && (
                      <Speciality
                        label="Specialty"
                        required="true"
                        name="speciality_ids"
                        setFieldValue={setFieldValue}
                        values={values}
                        className="col-span-1"
                      />
                    )}
                  {/* subcontractors */}
                  {company.profile_type.id === 3 && (
                    <Trades name="trade_ids" required />
                  )}
                </div>
                
                
                <div className="mb-5">
                  <div className="font-roboto font-semibold text-text-primary-900 text-[18px]">Contact Details</div>
                  <p className="font-roboto text-sm text-text-tertiary-600 mt-2">These are your personal details linked to your Procuracon account. Please ensure they're up-to-date.</p>
                </div>
                <div className="flex justify-between items-center gap-6 w-full">
                  <TextInput
                    icon={true}
                    iconPosition="left"
                    customIcon="briefcase"
                    label="Contact Person Name"
                    required="true"
                    name="contact_name"
                    type="text"
                    className="flex-1"
                    flexGrow="1"
                    guideLine="This is the main point of contact for your account and profile."
                  />

                  <TextInput
                    icon={true}
                    iconPosition="left"
                    customIcon="search-sm"
                    label="Phone Number"
                    required="true"
                    name="phone"
                    type="text"
                    flexGrow="1"
                    className="flex-1"
                  />
                </div>
                <div className="flex justify-between items-center gap-6 w-full">
                  <TextInput
                    label="Email Address"
                    required="true"
                    name="email"
                    type="text"
                    flexGrow="1"
                    className="flex-1"
                  />
                  <div className="flex-1"></div>
                </div>
                <div className="row">
                  <div className="col xl-12">
                    <div className="row">
                      <div className="col xl-12">
                

                      </div>
                    </div>
                  </div>
                  
                  <div className="col xl-8">
                    {status && !_.isEmpty(errors) && (
                      <FlashError
                        heading={errorMessages[status]?.heading || status}
                        text={errorMessages[status]?.description || status}
                        margin="0px 0px 20px 0px"
                      />
                    )}
                    {isErrorBusinesName && <>
                    <FlashError
                      heading={errorBusinesName.message}
                      text="The name has already been taken."
                      margin="0px 0px 20px 0px"
                    />
                  </>}
                  </div>
                </div>
                <TextInput
                  name="company_id"
                  value={initialValues.company_id}
                  type="hidden"
                />
                <div className="flex justify-end mt-12">
                  <Submit
                    id="btnCompanyInformation"
                    type="submit"
                    onClick={() => {
                      setShowToast(true);
                    }}
                    isSubmitting={isSubmitting}
                    spinner
                    ref={btnCompanyInformation}
                    text="Save Profile"
                    submittingText="Saving..."
                    disabled={!_.isEmpty(errors)}
                    className="!h-[50px]"
                  />
                </div>
                <OnSubmitValidationError callback={onSubmitValidationError} />
              </div>
            </TabContent>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};
const errorMessages = {
  required: {
    heading: "* Required Field",
    description: "There are incomplete required fields, Please complete them.",
  },
  connection: {
    heading: "Connection not established",
    description: "Unable to process your request",
  },
  maxCharacter: {
    heading: "Input has reached max character",
    description: "Input has reached max character",
  },
  
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const companyId = state.search.activeCompany.id;
  const globalPresenceData = state.globalPresence.data;

  return {
    company: company,
    companyId: companyId,
    globalPresenceData,
    initialValues: {
      logo: company.logo_path || "",
      banner_image: company.banner_path || "",
      name: company.name || "",
      company_number: company.company_number || "",
      sector_ids: Array.isArray(company.sector)
        ? company.sector.map((sector) => sector.id)
        : [],
      speciality_ids: Array.isArray(company.speciality)
        ? company.speciality.map((speciality) => speciality.id)
        : [],
      size_type_id: company.size_type_id || "",
      description: company.description || "",
      keywords: company.keywords || [],
      services: company.services || [],
      contact_name: company.contact_name || "",
      phone: company.phone || "",
      email: company.email || "",
      website: company.website || "",
      company_id: company.id || "",
      status: company.status || "",
      disciplines: company.disciplinesFormatted.length
        ? company.disciplinesFormatted
        : [{ expertise: "", disciplines: [] }],
      profile_type: company.profile_type.id,
      trade_ids: Array.isArray(company.trade)
        ? company.trade.map((trade) => trade.id)
        : [],
      years_trading: parseInt(company.years_trading) || "",
      global_presence: company.global_presence.map((g) => {
        return {
          value: g.id,
          label: g.name,
        };
      }),
      abn: company.abn,
      indigenous_buisness: company.indigenous_buisness,
    },
  };
};

export const CompanyInformationForm = connect(mapStateToProps, {
  submitCompanyInformationForm,
  getCurrentUser,
  getCompany,
  getGlobalPresence,
})(CompanyInformationFormComponent);

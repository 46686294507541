import { navigate } from "@reach/router";
import { useLocation } from "@reach/router";
import apiV2 from "api-v2";
import { Icon } from "assets/icons/Icon";
import { DropdownItem, DropdownMenu, DropdownMenuDrawer, DropdownMenuTrigger, MenuDivider } from "components/RFQ/MenuDropdown";
import React from "react";
import { showToast } from "utils/helpers";

const EditDropdown = ({ id, currentTab, noBg, refreshData, isEditable }) => {
  const location = useLocation();
  const redirectChecker = location.pathname.includes('request-for-quote/') ? `edit-rfq/${id}` : `request-for-quote/edit-rfq/${id}`;
  const isArchived = "archived"

  const actionFunc = async (currentTab) => {
    let action = ""
    if (currentTab === isArchived) {
      action = "unarchived"
    } else {
      action = "archived"
    }
    if (action !== "") {
      try {
        let res = await apiV2.post(`rfq/${id}/${action}`)
        if (res.status === 200) {
          showToast(res?.data?.message, res?.data?.title, true)
          refreshData()
        }
      } catch (err) {
        showToast(err?.response?.data?.message, 'Error')
      }
    }
  }

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger icon noBg={noBg} mutedIcon></DropdownMenuTrigger>
        <DropdownMenuDrawer>
          {isEditable && <> 
            <DropdownItem className="w-full" onClick={() => navigate(redirectChecker)}>
              <Icon icon='edit-icon' />
              <p className="font-roboto font-medium text-text-secondary-700" style={{fontSize:'14px'}}>Edit RFQ</p>
            </DropdownItem>
            <MenuDivider />
            </>}
          <DropdownItem onClick={() => actionFunc(currentTab)}>
            <Icon icon='archive' />
            <p className="font-roboto font-medium text-text-secondary-700" style={{fontSize:'14px'}}>{currentTab === isArchived ? 'Unarchive' : 'Archive'}</p>
          </DropdownItem>
        </DropdownMenuDrawer>
      </DropdownMenu>
    </div>
  );
};

export default EditDropdown;

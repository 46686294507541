import React from 'react';
import { connect } from 'react-redux';

import { DocumentForm } from './DocumentForm';
import { addDocument } from '../../actions/companyActions';
import { showToast } from 'utils/helpers';

export const AddDocumentComponent = ({ companyId, initialValues, profileTypeId, onSuccessfulSubmit, addDocument, ...props }) => {
    const handleSubmit = async (values, { setErrors }) => {
        try {
            await addDocument(companyId, values);
            onSuccessfulSubmit(); //closeModal
            showToast('Document added successfully.', 'Success', true);
        } catch (e) {
            const error = await e.response.json();
            setErrors(error.errors);
            showToast(error.message, 'Error');
        }
    };

    return <DocumentForm initialValues={initialValues} profileTypeId={profileTypeId} handleSubmit={handleSubmit} title='Add New Document' />;
};

const mapStateToProps = (state, ownProps) => {
    const companyId = state.search.activeCompany.id;

    return {
        companyId: companyId,
        profileTypeId: state.search.activeCompany.profile_type.id,
        initialValues: {
            name: '',
            category_id: '',
            sub_category_id: '',
            documentable_type: 'App\\Models\\Company',
            file: '',
            extra_info: {
                expiry_date: '',
                accreditation: '',
                relevant_year: '',
                value: '',
                audited: ''
            }
        }
    };
};

export const AddDocument = connect(mapStateToProps, { addDocument })(AddDocumentComponent);

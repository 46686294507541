import React, { useEffect, useRef, useState } from "react";
import { Router, Redirect, navigate } from "@reach/router";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-google";
import TagManager from "react-gtm-module";
import 'swiper/swiper-bundle.css'
import styled, { ThemeProvider } from "styled-components";
import { ThemeProvider as ChakraThemeProvider } from "@chakra-ui/react";
import { connect } from "react-redux";
import "normalize.css";
import "./utils/yupLocale";
import "tippy.js/dist/tippy.css";
import "react-dates/lib/css/_datepicker.css";

import "styles/App.scss";
import GlobalStyles from "styles/base";
import { Toast } from "./components/Toast";
import { theme } from "theme";
import { showToast } from "./utils/helpers"; // Import showToast

// import { GlobalNav } from "./components/GlobalNav";
import { Login } from "./components/Login/Login";
import { GuestAuthForm } from "./components/GuestAuthForm";
import { ResetPassword } from "./components/ResetPassword/ResetPassword";
import { MultiStep } from "./components/SignUpForm/MultiStep";
//Pages/Layouts
import { Company } from "./routes/Company";
import { Account } from "./routes/Account";
import { NotFound } from "./routes/NotFound";
import { NotAccessible } from "./routes/NotAccessible";
import { CompanyListing } from "./routes/CompanyListing";
import { Placeholder } from "./routes/Placeholder";
import { Search } from "routes/search/Search";
import { useDispatch } from "react-redux";
import { setAccessToken } from "./actions/authActions";
import { PrivacyPolicy } from "./routes/PrivacyPolicy";
import { getGuestUser } from "./actions/guestActions";
import { TermsConditions } from "./routes/TermsConditions";
import { PasswordResetForm } from "./components/PasswordResetForm/PasswordResetForm";
import { VerifyEmail } from "./components/Login/VerifyEmail";
import { EmailVerification } from "./components/Login/EmailVerification";
import { isPAPLUsers } from "./utils/helpers";
import _ from "lodash";
import { Suspended } from "./components/Login/Suspended";
import { CustomToast } from "./components/Toast/CustomToast";
import { AUTHENTICATE_GUEST_USER } from "./actions/actionTypes";
import { GuestSignUpWelcome } from "components/GuestAuthForm/GuestSignUpWelcome";
import { ShareProfile } from "./routes/ShareProfile";
import "./index.css";
import { Supplier } from "routes/Supplier";
import { Guest } from "routes/Guest";
//------ Uncomment the below line to import getCsrfCookie function from api-v2 file ------
// import { getCsrfCookie } from "api-v2";
import TwoFactor from "components/TwoFactor/TwoFactor";
import TwoFactorSetup from "components/TwoFactorSetup/TwoFactorSetup";

const AppWrapper = styled.div`
  background-color: ${theme.colors.white};
  margin-left: "0px";
  min-height: 100vh;

  ${theme.mediaQueries.xxl} {
    margin-left: "0px";
  }
`;

function App({ company, getGuestUser, ...props }) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const guest_user = JSON.parse(localStorage.getItem("guest_user"));
  const role = localStorage.getItem("role") ? localStorage.getItem("role") : "";
  const dispatch = useDispatch();

  const { setAccessToken, user } = props;

  useEffect(() => {
    // Function to update tabIndex based on className
    const updateTabIndexes = () => {
      const ignoreClasses = ["ql-bold", "ql-italic", "ql-list"];
      const elements = document.querySelectorAll("*");
      elements.forEach((element) => {
        // Enable tabs only when have focusable classname
        if (
          element.classList.contains("focusable") ||
          element.classList.contains("ql-editor")
        ) {
          element.setAttribute("tabIndex", "0");
        } else {
          // ql-formats
          if (element instanceof HTMLInputElement) {
            element.setAttribute("tabIndex", "0");
          } else if (element instanceof HTMLButtonElement) {
            // disable react quill toolbar accesbility
            const ignoreClass = ignoreClasses.some((cls) =>
              element.classList.contains(cls),
            );
            if (ignoreClass) {
              element.setAttribute("tabIndex", "-1");
            }
          } else {
            element.setAttribute("tabIndex", "-1");
          }
        }
      });
    };

    // Initial update
    updateTabIndexes();

    // Update tabIndex on DOM changes
    const observer = new MutationObserver(updateTabIndexes);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  // an effect when logging in and logging out
  useEffect(() => {
    let twoFactorEnabled = localStorage.getItem("two_factor_enabled");
    let twoFactorVerified = localStorage.getItem("two_factor_verified");
    let accessToken = localStorage.getItem("access_token");
    let loggedInUser = JSON.parse(localStorage.getItem("user")) || user;
    let companyIdString = localStorage.getItem("company_id");
    let companyId = JSON.parse(
      companyIdString !== "undefined" ? companyIdString : null,
    );
    const url_path = window.location.pathname.toString();
    const redirectLoginPaths = [
      "/login",
      "/verify-email",
      "/",
      "/verification",
      "/guest/signup",
      "/two-factor",
      "/two-factor-setup",
    ];

    if (
      accessToken !== undefined &&
      accessToken !== "" &&
      accessToken !== null
    ) {
      
      if((twoFactorEnabled === "true" && twoFactorVerified === "false") || 
         (twoFactorEnabled === "false" && isPAPLUsers(user))
      ){
        setIsLoggedIn(false);
        return;
      }else{
        setIsLoggedIn(true);
      }
      
      if ((loggedInUser?.email_verified && !isPAPLUsers(user)) || 
          (isPAPLUsers(user) && twoFactorVerified === "true" && companyId !== null && isLoggedIn)) {
        setIsVerified(true);
        if (redirectLoginPaths.includes(url_path) !== false) {
          if (url_path === "/verification") {
            showToast("Already Logged In. Please logout and try again.", "Info", true);
          }
          if (props.user.shared_profiles?.length > 0) {
            navigate(`/account/${companyId}/company-profile/shared-companies`);
          } else {
            navigate(
              `/account/${companyId}/${role === "client" ? "projects" : "request-for-quote"}`,
            );
          }
        }
      } else if (url_path === "/login") {
        setIsVerified(false);
        navigate("/verify-email");
      }
    } else {
      setIsLoggedIn(false);
      // navigate('/login');
    }
    //------ Uncomment the below line to call getCsrfCookie function ------
    // getCsrfCookie();

    // Redirect guests to home; routes will take care of the rest
    if (guest_user && url_path.includes("/guest/signup")) {
      navigate("/");
    }
    if (redirectLoginPaths.includes(url_path) && guest_user) {
      navigate("/account/request-for-quote");
    }
    // eslint-disable-next-line
  }, [props.access_token, user, guest_user, props?.user?.shared_profiles, role]);

  // to check suspension
  const suspended = !_.isEmpty(company) && !company.active;
  if (suspended) navigate("/suspended");
  // an effect to setAccessToken on refresh
  useEffect(() => {
    let accessToken = localStorage.getItem("access_token");
    let twoFactorEnabled = localStorage.getItem("two_factor_enabled");
    let twoFactorVerified = localStorage.getItem("two_factor_verified");

    const twoFactorRequired = twoFactorEnabled === "true" || isPAPLUsers(user);
    const twoFactorPassed = !twoFactorRequired || (twoFactorRequired && twoFactorVerified === "true")

    if (guest_user) {
      dispatch({
        type: AUTHENTICATE_GUEST_USER,
        payload: guest_user,
      });
      getGuestUser(guest_user.id);
    }

    if (
      accessToken !== undefined &&
      accessToken !== "" &&
      accessToken !== null &&
      twoFactorPassed
    ) {
      setAccessToken(accessToken,twoFactorRequired);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAccessToken, dispatch, getGuestUser]);

  // for recaptcha
  useEffect(() => {
    loadReCaptcha();
  }, []);
  const ref = useRef();

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-NFM885JS",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  // const guestCompanyId = props.guestUser.login
  //   ? props.guestUser?.shared_profiles[0].company_id
  //   : guest_user?.shared_profiles[0].company_id;

  return (
    <ThemeProvider theme={theme}>
      <ChakraThemeProvider theme={theme}>
        <GlobalStyles />
        <ReCaptcha
          ref={ref}
          size="invisible"
          render="explicit"
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        />
        {isLoggedIn === null ? (
          <></>
        ) : (
          <>
            {isLoggedIn ? (
              isVerified && !suspended ? (
                <AppWrapper
                  className="App"
                  company={company}
                  onClick={() => {
                    if (showPopUp) setShowPopUp(!showPopUp);
                  }}
                >
                  <Router style={{ display: "flex" }}>
                    <NotFound default />
                    <NotAccessible path="/not-accessible" />
                    <Company path="company/*" />
                    <Placeholder
                      path="home"
                      title="Project Dashboard - Coming Soon"
                    />
                    <Company path="company/:companyId/*" />
                    {role === "supplier" ? (
                      <Supplier path="account/:companyId/*" />
                    ) : role === "client" ? (
                      <Account path="account/:companyId/*" />
                    ) : null}
                    <Search path="search/*" title="Search" />
                    <CompanyListing path="companies" />
                    <CompanyListing path="accounts/*" admin />
                    <PrivacyPolicy path="privacy-policy" />
                    <TermsConditions path="terms-and-conditions" />
                    <ShareProfile path="/share-profile" />
                    <Redirect from="/" to="/" noThrow />
                  </Router>
                </AppWrapper>
              ) : (
                <>
                  <Router style={{ display: "flex" }}>
                    <NotFound default />
                    <VerifyEmail path="verify-email" />
                    <Suspended path="suspended" />
                    <EmailVerification path="verification" />
                    <PrivacyPolicy path="privacy-policy" />
                    <TermsConditions path="terms-and-conditions" />
                  </Router>
                </>
              )
            ) : props.guestUser.login ? (
              <AppWrapper
                className="App"
                company={company}
                onClick={() => {
                  if (showPopUp) setShowPopUp(!showPopUp);
                }}
              >
                <Router style={{ display: "flex" }}>
                  <Guest path="/account/*" />
                  <NotFound default />
                </Router>
              </AppWrapper>
            ) : (
              // <AppWrapper
              //   className="App"
              //   onClick={() => {
              //     if (showPopUp) setShowPopUp(!showPopUp);
              //   }}
              // >
              //   <GlobalNav showPopUp={showPopUp} setShowPopUp={setShowPopUp} />
              //   <Router style={{ display: "flex" }}>
              //     <NotFound default />
              //     {/* <Company path="company/:companyId/*" /> */}
              //     <MultiStep path="signup" />
              //     <Redirect
              //       from="/guest/signup/*"
              //       to={`/company/${guestCompanyId}/projects`}
              //       exact
              //       noThrow
              //     />
              //     <Redirect
              //       from="/guest/login"
              //       to={`/company/${guestCompanyId}/projects`}
              //     />
              //     <Redirect
              //       from="*"
              //       to={`/company/${guestCompanyId}/projects`}
              //       noThrow
              //     />
              //     <NotAccessible path="/not-accessible" />
              //     <NotFound default />
              //     <Redirect from="/share-profile" to="/" noThrow />
              //   </Router>
              // </AppWrapper>
              <Router style={{ display: "flex" }}>
                <Login path="login/" />
                <GuestAuthForm path="/guest/login" />
                <GuestAuthForm path="/guest/signup" />
                <GuestSignUpWelcome path="/thank-you" />
                <Login path="/" />
                <TwoFactor path="two-factor/" />
                <TwoFactorSetup path="two-factor-setup/" />
                <MultiStep path="signup" />
                <ResetPassword path="reset-password" />
                <ResetPassword path="guest/reset-password" />
                <PasswordResetForm path="reset-password-form" />
                <PasswordResetForm path="guest/reset-password-form" />
                <EmailVerification path="verification" />
                <PrivacyPolicy path="privacy-policy" />
                <TermsConditions path="terms-and-conditions" />
                <NotFound default />
                <Redirect from="/share-profile" to="/guest/signup" noThrow />
                <Redirect from="*" to="/login" noThrow />
              </Router>
            )}
          </>
        )}
        <Toast />
        <CustomToast />
      </ChakraThemeProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    user: Object.keys(state.auth.user)?.length > 0 ? state.auth.user : JSON.parse(localStorage.getItem("user")),
    guestUser: state.auth.guestUser,
    company: state.auth.current_company,
  };
};

export default connect(mapStateToProps, { setAccessToken, getGuestUser })(App);

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useField } from "formik";
import isUrl from "is-url";
import { FileDetailsStyled, FileUploadStyled, RemoveButton } from "./styled";
import { ImageUploadStyled } from "../ImageUpload/styled";
import { ReactComponent as DocumentIcon } from "../../../icons/document.svg";
import { Error } from "../Error";
import { ReactComponent as Cross } from "../../../icons/cross-circle.svg";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { showToast } from "utils/helpers";

export const FileLink = (props) => {
  return (
    <a
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
      className="document__title"
    >
      {props.name}
    </a>
  );
};

export const FileUpload = ({
  setFieldValue,
  name,
  values,
  multi,
  acceptType = "",
  ...props
}) => {
  //eslint-disable-next-line
  const [field, meta] = useField(name);
  const maxFileSize = 10485760
  const { getRootProps, getInputProps } = useDropzone({
    multiple: multi,
    accept: acceptType,
    maxSize:maxFileSize,
    onDrop: useCallback(
      (acceptedFiles,rejectedFiles) => {
        rejectedFiles.forEach((file) => {
        file.errors &&
          file.errors.forEach(
            (error) =>
              error.code === "file-too-large" &&
              showToast(`Maximum file size is ${10}MB.`, "Error")
          );
          return;
      });
        if (multi) {
          setFieldValue(name, values[name].concat(acceptedFiles));
        } else if(acceptedFiles[0].size <= 10485760) {
          const specialRegex = /[!@#$%^&*+{}[\]:;<>,?~\\/]/
          if(specialRegex.test(acceptedFiles[0].name)){
            showToast(`File upload unsuccessful. Please ensure file names do not contain any special characters. `, "Error")
          }else{
            setFieldValue(name, acceptedFiles[0]);
          }
        }
      },
      [multi, name, values, setFieldValue]
    ),
    
  });

  const handleFileRemove = (event, id) => {
    event.stopPropagation();
    //copy state array and remove index, then re-set
    let attachments = "";

    if (Array.isArray(values[name])) {
      attachments = [...values[name]];
      attachments.splice(id, 1);
    } else {
      attachments = "";
    }

    setFieldValue(name, attachments);
  };

  return (
    <FileUploadStyled className={props.className}>
      {props.label && (
        <LabelForm label={props.label} required={props.required}></LabelForm>
      )}

      <ImageUploadStyled
        files
        width="100%"
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        {multi && "Drag and drop files here, or click to select."}

        {!multi
          ? values[name]
            ? "To replace the uploaded document, drag and drop a file here, or click to select"
            : "Drag and drop a file here, or click to select."
          : ""}
      </ImageUploadStyled>

      {multi &&
        (values[name] || values[name].length) &&
        values[name].map((file, i) => (
          <FileDetailsStyled key={i}>
            <DocumentIcon />{" "}
            {file.name ? (
              file.name
            ) : (
              <a href={file} target="_blank" rel="noopener noreferrer">
                {file}
              </a>
            )}
            <RemoveButton onClick={handleFileRemove}>
              <Cross />
            </RemoveButton>
          </FileDetailsStyled>
        ))}

      {!multi && values[name] && (
        <FileDetailsStyled>
          <DocumentIcon />{" "}
          {isUrl(values[name]) ? (
            <FileLink
              name={values[name].split("/").pop()}
              url={values[name].name || values[name]}
            />
          ) : (
            <p>{values[name].name}</p>
          )}
          <RemoveButton onClick={handleFileRemove}>
            <Cross />
          </RemoveButton>
        </FileDetailsStyled>
      )}

      <Error meta={meta} errorBottom="-4px" />
    </FileUploadStyled>
  );
};

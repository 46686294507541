import React from 'react';
import { connect } from 'react-redux';
import { showToast } from '../../utils/helpers'; // Import showToast from helpers

import { DocumentForm } from './DocumentForm';
import { editDocument } from '../../actions/companyActions';

export const EditDocumentComponent = ({ documentId, initialValues, onSuccessfulSubmit, editDocument, ...props }) => {
    const handleSubmit = async (values, { setErrors }) => {
        try {
            await editDocument(documentId, values);
            onSuccessfulSubmit(); //closeModal
            showToast('Document updated successfully.', 'Success', true);
        } catch (e) {
            const error = await e.response.json();
            setErrors(error.errors);
            showToast(error.message, 'Error');
        }
    };

    return <DocumentForm initialValues={initialValues} handleSubmit={handleSubmit} title='Edit Document' />;
};

const mapStateToProps = (state, ownProps) => {
    const values = ownProps.initialValues;

    let category_id = '';

    if (values.category && values.category.parent_id !== 0) {
        category_id = values.category.parent_id;
    } else if (values.category && values.category.id) {
        category_id = values.category.id;
    }

    return {
        documentId: values.id,
        initialValues: {
            name: values.name || '',
            category_id,
            sub_category_id: !values.category.parent_id ? '' : values.category?.id, // only set sub_category_id if the cat has a parent (seems the API doesn't differentiate parent and child categories)
            file: values.storage_path,
            extra_info: values.extra_info || {}
        }
    };
};

export const EditDocument = connect(mapStateToProps, { editDocument })(EditDocumentComponent);

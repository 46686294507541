import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import tw, { styled, css } from "twin.macro";
import { Button } from "components/RFQ/Button";
import SearchBar from "components/RFQ/SearchBar/SearchBar";
import FilterButton from "components/FilterButton/FilterButton";
import FilterSupplier from "components/RFQ/FilterSupplier/FilterSupplier";
import { SupplierListDrawer } from "../SupplierListDrawer";
import { getSupplierLists } from "actions/RFQ/supplierListsActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { assignSupplierListToProject } from "actions/RFQ/projectsAction";
import { Icon } from "assets/icons/Icon";
import BadgeComp from "components/RFQ/BadgeComp/BadgeComp";
import { showToast } from "utils/helpers";

//   const FormikStyled = styled(Formik)(() => [tw`overflow-y-auto`]);

const DrawerHeaderStyled = styled(DrawerHeader)(() => [
  tw`text-black py-6 border-b`,
  css`
        .close-text {
          font-weight: 400;
          font-size: 14px;
          cursor: pointer;
        }
    
        .header-container {
          display: flex;
          justify-content: space-between;
          justify-items: center;
          align-items: center;
        }
    
        .clear-container {
          display: flex;
          justify-items: center;
          place-items: center;
        }
      `,
]);

const DrawerBodyStyled = styled(DrawerBody)(() => [tw`p-0`]);

export const AddSupplierListDrawer = ({ projectData, onCloseContainer, isOpenContainer, projectId, defaultLists, setSupplierLists, ...props }) => {
  const dispatch = useDispatch();
  const supplierLists = useSelector(state => state?.rfq?.supplierLists.supplier)
  const isLoading = useSelector(state => state?.rfq?.supplierLists.loading)
  const [currentTab, setCurrentTab] = useState('myDepartment');
  const currentPage = supplierLists?.[currentTab]?.meta?.current_page
  const [filters, setFilters] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [search, setSearch] = useState('');
  const isCurrent = (tab) => currentTab === tab;
  const currentSuppliersData = (tab) => supplierLists?.[tab]?.data || [];

  const [checkedRow, setCheckedRow] = useState(defaultLists);
  const hasChanged = () => {
    return checkedRow.map(item => item.id).sort().toString() !== defaultLists.map(item => item.id).sort().toString();
  };
  const pageChecker = (pages = 'current_page') => {
    let page = 1;

    if (JSON.stringify(supplierLists) !== '{}') {
      page = supplierLists?.[currentTab]?.meta?.[pages];
    }
    return page;
  };

  const [currPage, setCurrPage] = useState(pageChecker('current_page'));
  const [lastPage, setLastPage] = useState(pageChecker('last_page'));

  const onScroll = async () => {
    const { clientHeight, scrollHeight, scrollTop } = document.getElementById("scrollable-drawer");
    if (!isLoading && !loadingPagination && scrollTop + clientHeight >= scrollHeight - 180) {
      if (currPage < lastPage) {
        setLoadingPagination(true);
        dispatch(getSupplierLists({ limit: 24, page: currPage + 1, search, filters }))
        setLoadingPagination(false);
      }
    }
  };


  useEffect(() => {
    const handleScroll = () => {
      onScroll();
    };
    const scrollableElement = document.getElementById("scrollable-drawer");

    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [currPage, lastPage, supplierLists, isLoading, loadingPagination, isOpenContainer]);


  useEffect(() => {
    setLoadingPagination(false);
    // eslint-disable-next-line
  }, [currPage]);

  useEffect(() => {
    setCurrPage(1)
    dispatch(getSupplierLists({ page: 1, limit: 24, search, filters, reset: true }))
    //eslint-disable-next-line
  }, [search, filters]);

  useEffect(() => {
    setCurrPage(pageChecker('current_page'));
    setLastPage(pageChecker('last_page'));
    //eslint-disable-next-line
  }, [supplierLists, currentPage, currentTab]);

  const addSupplierHandler = () => {
    assignSupplierListToProject(projectId, { supplier_list_id: checkedRow?.length > 0 ? checkedRow.map(item => item.id) : null })
      .then(res => {
        setSupplierLists(checkedRow)
        onCloseContainer()
        showToast(res?.message, 'Success', true)
        setFilters(null)
        setSearch('')
      })
      .catch(err => {
        showToast('Error while adding supplier list', 'Error')
      })
  }
  return (
    <div>
      <Drawer
        placement={"right"}
        isOpen={isOpenContainer}
        onClose={onCloseContainer}
        size="xl"
        blockScrollOnMount={false}
        {...props}
      >
        <DrawerOverlay onClick={onCloseContainer} />
        <DrawerContent   >
          <DrawerHeaderStyled borderBottomWidth="1px">
            <div className="header-container flex items-center">
              <p className="text-base font-semibold">Add/Remove Supplier List for this project</p>
              <div className="clear-container">
                <Button btntype="plain" type={"button"}  className="close-text font-roboto font-medium" style={{fontSize:'14px'}} onClick={() => setCheckedRow([])}>Clear all</Button>
                <Button btntype="plain-icon" type={"button"} onClick={onCloseContainer}>
                  <Icon icon={"close"} />
                </Button>
              </div>
            </div>
          </DrawerHeaderStyled>
          <DrawerBodyStyled id='scrollable-drawer'>
            <div>
              <Flex style={{ justifyContent: 'space-between' }} className="p-5">

                <Flex style={{ gap: '8px' }}>
                  <Button btntype='tab' active={isCurrent('myDepartment')} onClick={() => setCurrentTab('myDepartment')}>
                    My Suppliers
                    <BadgeComp active={isCurrent('myDepartment')} total={currentSuppliersData('myDepartment')?.length} />
                  </Button>
                  <Button btntype='tab' active={isCurrent('companyApprovedList')} onClick={() => setCurrentTab('companyApprovedList')}>
                    Panel List
                    <BadgeComp active={isCurrent('companyApprovedList')} total={currentSuppliersData('companyApprovedList')?.length}/>
                  </Button>
                </Flex>
                <Flex style={{ gap: '8px' }}>
                  <SearchBar setState={setSearch} />
                  <FilterButton onClick={onOpen} filters={filters} />
                  <FilterSupplier isOpen={isOpen} onClose={onClose} filters={filters} setFilters={setFilters} />
                </Flex>
              </Flex>
            </div>
            <div>
              <SupplierListDrawer projectData={projectData} currPage={currPage} data={supplierLists?.[currentTab]?.data} isLoading={isLoading} checkedRow={checkedRow} setCheckedRow={setCheckedRow} />
            </div>
            <div className="w-full px-4 py-6 bg-white sticky bottom-0 z-10 h-auto shadow-inner">
              <Button className='!w-full' disabled={!hasChanged()} onClick={addSupplierHandler}>Apply</Button>
            </div>
          </DrawerBodyStyled>
        </DrawerContent>
      </Drawer>
    </div>
  );
};


import { Button } from "components/RFQ/Button";
import { TabHeader } from "components/TabHeader";
import React, { useEffect, useState } from "react";
import tw, { styled, css } from "twin.macro";
import { ReactComponent as BackIcon } from "../../../assets/icons/flip-backward.svg";
import { navigate } from "@reach/router";
import { Form, Formik } from "formik";
import { Flex, Spinner } from "@chakra-ui/react";
import { FieldForm } from "components/RFQ/Input/Input";
import { DateInput } from "components/DateInput";
import { TextArea } from "components/FormInputs/TextArea";
import { useDispatch } from "react-redux";
import {
  createSupplierList,
  getSupplierListById,
} from "actions/RFQ/supplierListsActions";
import * as yup from "yup";
import { useSelector } from "react-redux";
import NoBorderButton from "components/RFQ/NoBorderButton/NoBorderButton";
import apiV2 from "api-v2";
import moment from "moment";
import { showToast } from "utils/helpers";

const NewSupplierContainer = styled.div(() => [
  tw`relative overflow-hidden`,
  css`
    .buttons-container {
      gap: 8px;
    }
    .container-content {
      padding-right: 24px;
      padding-left: 24px;
    }
  `,
]);

const BackButton = ({ isNew }) => {
  return (
    <Button
      btntype={"icon"}
      onClick={() => navigate(isNew ? "./" : "../")}
      type="button"
    >
      <BackIcon style={{ width: "28px", height: "28px", marginRight: "0px" }} />
    </Button>
  );
};

const SupplierForm = ({
  isNew,
  isEdit,
  listId,
  isAddSupplier,
  listIDCompanies,
  closeDialog,
}) => {
  const isLoading = useSelector((state) => state.rfq?.supplierLists?.loading);
  const [isLoadingNow, setIsLoadingNow] = useState(isLoading);
  const dispatch = useDispatch();
  const [supplierList, setSupplierList] = useState(
    useSelector(
      (state) => state.rfq?.supplierLists?.supplier?.allDepartment?.data
    )?.find((list) => list.id === Number(listId)) || null
  );
  const [loadList, setLoadList] = useState(false);
  const { company_id, company_admin } = useSelector(
    (state) => state?.auth?.user
  );
  const options = [
    {
      label: "My Suppliers",
      value: 0,
    },
    {
      label: "Panel List",
      value: 1,
    },
  ];

  useEffect(() => {
    if (isAddSupplier) {
      setIsLoadingNow(false);
    }
  }, [isAddSupplier]);

  useEffect(() => {
    if (listId && !isNew && !supplierList) {
      setLoadList(true);
      getSupplierListById(listId)
        .then((res) => {
          setSupplierList(res.data);
          setLoadList(false);
        })
        .catch((err) => {
          setLoadList(false);
        });
    }
  }, [listId, isNew, supplierList]);

  const initialValues = {
    name: "",
    is_company_approved_list: 0,
    code: "",
    start_date: moment(),
    end_date: moment().add(6, "months"),
    description: "",
  };

  const initialValuesSupplier = {
    name: supplierList?.name || "",
    is_company_approved_list: supplierList?.is_company_approved_list || 0,
    code: supplierList?.code || "",
    start_date: supplierList?.startDate || "",
    end_date: supplierList?.endDate || "",
    description: supplierList?.description || "",
  };

  const yupValidation = yup.object().shape({
    name: yup.string().required("Name is required"),
    // code: yup.string().required("Code is required"),
    // start_date: yup.string().required("Start date is required"),
    // end_date: yup.string().required("End date is required"),
    description: yup.string().required("Description is required"),
  });

  const submitHandler = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (isAddSupplier) {
      setIsLoadingNow(true);
      values.isUpdate = 0;
      values.end_date = moment(values.end_date).format("YYYY-MM-DD");
      values.start_date = moment(values.start_date).format("YYYY-MM-DD");
      values.suppliers = listIDCompanies;
      try {
        let res = await apiV2.post(`supplier/save`, values);
        if (res.status === 200) {
          closeDialog();
          showToast(res?.data?.message, res?.data?.title, true);
          setIsLoadingNow(false);
          let supplierlistId = res?.data?.data?.id;
          let supplierlistName = res?.data?.data?.name;
          navigate(
            `/account/${company_id}/supplier-list/${supplierlistId}/${supplierlistName}`
          );
        }
      } catch (err) {
        showToast(err?.message, "Error");
      }
    } else {
      if (isNew) dispatch(createSupplierList(values));
      if (company_admin) {
        if (isEdit) dispatch(createSupplierList(values, listId));
      } else {
        if (supplierList?.is_company_approved_list === 1) {
          showToast("You are not authorized to perform this action", "Error");
        } else {
          if (isEdit) dispatch(createSupplierList(values, listId));
        }
      }
    }
  };

  const listTypeDesc = (type) =>
    type === 1
      ? "Panel (Admin Permissions Only)\nA Panel is more formal, involves a competitive process, suppliers are contractually engaged with a defined duration."
      : "This Supplier List is formal, used as a guideline for procurement, offers flexibility, and typically does not involve binding contracts. Also known as a Preferred Supplier List";

  return (
    <NewSupplierContainer>
      {!loadList ? (
        <Formik
          initialValues={isNew ? initialValues : initialValuesSupplier}
          onSubmit={submitHandler}
          validationSchema={yupValidation}
        >
          {({
            values,
            setValues,
            resetForm,
            setFieldValue,
            isSubmitting,
            dirty,
            isValid,
          }) => (
            <Form>
              {!isAddSupplier && (
                <TabHeader
                  icon={<BackButton isNew={isNew} />}
                  heading={
                    isNew
                      ? "Create New Supplier List"
                      : `Edit ${supplierList?.name}`
                  }
                  isLoading={loadList}
                />
              )}
              <div className="container-content h-full relative min-h-[60vh]">
                {company_admin && (
                  <Flex gap={8} marginBottom={8}>
                    <div className="w-full p-8 bg-[#EEF5FFB2] space-y-2 rounded-md">
                      <FieldForm
                        className={isAddSupplier?"w-[calc(100%-1rem)]":"w-[calc(50%-1rem)]"}
                        label="List type"
                        name="is_company_approved_list"
                        type="select"
                        placeholder="My Suppliers"
                        options={options}
                      />
                      <p className={`${isAddSupplier?"w-full":"w-1/2"} whitespace-break-spaces`}>
                        {listTypeDesc(values?.is_company_approved_list)}
                      </p>
                    </div>
                  </Flex>
                )}
                <Flex gap={8} marginBottom={8}>
                  <FieldForm
                    label="Name of Supplier List"
                    placeholder="E.g. Lead Design Architects"
                    name="name"
                    required={true}
                  />
                  <FieldForm
                    label="Supplier List Code"
                    placeholder="E.g. 0001 LDA"
                    name="code"
                  />
                </Flex>
                <Flex gap={8} ma>
                  <DateInput
                    label="Start Date"
                    name="start_date"
                    className="!font-regular"
                    placeholder="15 Oct 2023"
                  />
                  <DateInput
                    label="End Date"
                    name="end_date"
                    className="!font-regular"
                    placeholder="21 July 2025"
                  />
                </Flex>
                <Flex>
                  <TextArea
                    label="Supplier List Description"
                    name="description"
                    characterLimit={1500}
                    rfq
                    hint="Provide a brief description of your supplier list..."
                    className="w-full mt-2"
                    placeholder="E.g. Lead Design Architects for Non-Residential Projects. For Projects valued up to $40M"
                    required={true}
                  />
                </Flex>

              {!isAddSupplier ? (
                <Flex justifyContent="end" className="py-8 gap-4">
                  <NoBorderButton
                    onClick={() => {
                      resetForm();
                      isNew ? navigate("./") : navigate("..");
                    }}
                    text="Cancel"
                    type="button"
                    className="bg-white text-text-tertiary-600 text-[14px]"
                  />
                  <div className="flex buttons-container">
                    <Button
                      style={{ height: "50px" }}
                      type="submit"
                      disabled={isLoading}
                      className="disabled:!opacity-50"
                    >
                      {isLoading ? <Spinner /> : "Save"}
                    </Button>
                  </div>
                </Flex>
              ) : (
                <div className="flex buttons-container">
                  <Button
                    width="100%"
                    style={{ height: "50px" }}
                    type="submit"
                    disabled={!isValid || !dirty}
                    className="disabled:!opacity-50 mt-5 mb-5"
                  >
                    {isLoadingNow ? <Spinner /> : "Create Supplier List"}
                  </Button>
                </div>
              )}
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner
          className="absolute left-1/2 -translate-x-1/2 top-[30vh] "
          size="xl"
        />
      )}
    </NewSupplierContainer>
  );
};

export default SupplierForm;
